import { createContext, useEffect, useContext, useState } from "react";
import SocketObserver, { SocketEvents } from "src/observers/socketObserver";
import { SocketClient } from "src/services/socket.service";
import { MachineConfigurationContext } from "../../machineConfig/machineConfig.context";

type SocketContextType = {
    sendMessage: (topic: string, message: any) => void;
    error: string | null;
};

export const SocketContext = createContext<SocketContextType | null>(null);

type SocketProviderProps = {
    children: React.ReactNode;
};

export function SocketProvider({ children }: SocketProviderProps) {
    const [error, setError] = useState<string | null>(null);
    const machineConfigContext = useContext(MachineConfigurationContext);

    const socketObserverInstance = SocketObserver.getInstance();
    const socketClientInstance = SocketClient.getInstance();

    const handleSocketConnection = (data: { socket: SocketClient, status: boolean }) => {
        if (data.status) {
            setError(null);
        } else {
            setError("Socket Disconnected");
        }
    };

    const handleCashReceived = (data: any) => {
        console.log("SocketProvider -> data", data);

        // TODO: save to websql
        // TODO: call api with data.amount
        // Extra amount - 5
    }

    const handleSocketError = (error: string) => {
        setError(error);
    }

    const sendMessage = (topic: string, message: any) => {
        if (error === null && socketClientInstance) {
            console.log("SocketProvider -> message", message)
            socketClientInstance?.sendMessage(topic, message);
            return {
                success: true,
                error: false,
                message: "Message sent to socket successfully"
            }
        } else {
            return {
                success: false,
                error: true,
                message: error
            }
        }
    }


    useEffect(() => {
        const socketConnectSubscriptionId = socketObserverInstance.subscribe(SocketEvents.SOCKET_STATUS, handleSocketConnection);
        const socketErrorSubscriptionId = socketObserverInstance.subscribe(SocketEvents.SOCKET_ERROR, handleSocketError);

        return () => {
            socketObserverInstance.unsubscribe(socketConnectSubscriptionId);
            socketObserverInstance.unsubscribe(socketErrorSubscriptionId);
        }
    }, []);

    // useEffect(() => {
    //     if (machineConfigContext?.machineData?.socket_url) {
    //         SocketClient.init(machineConfigContext?.machineData?.socket_url);
    //     } else {
    //         setError("Socket URL not found");
    //     }
    // }, [machineConfigContext]);


    return (
        <SocketContext.Provider value={{
            sendMessage: sendMessage,
            error: error,
        }}>
            {children}
        </SocketContext.Provider>
    );
}