import { DialogAnimate } from "../animate";
import { Stack, Typography } from "@mui/material";
import { PaymentScreenLoader } from "src/components/paymentScreenLoader";
import Image from "src/components/image/Image";





type Props = {
    error: string;
    errorMessage: string;
};



export default function OverlayModalError({ error, errorMessage }: Props) {

    return (
        <DialogAnimate
            open={true}
            PaperProps={{
                sx: {
                    maxWidth: { md: "calc(100% - 48px)" },
                    maxHeight: { md: "calc(100% - 100px)" },
                    paddingTop: 5,
                    paddingBottom: 5,
                },
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    m: "auto",
                    maxWidth: 580,
                    textAlign: "center",
                    px: { xs: 2, sm: 0 },
                }}
            >
                <Typography variant="h4">{error}</Typography>
                {/* <Image
                    key={paymentImage}
                    alt="logo card"
                    src={paymentImage}
                    sx={{
                        height: 260,
                    }}
                /> */}
                <Typography variant="subtitle2">{errorMessage}</Typography>
                <PaymentScreenLoader />
            </Stack>
        </DialogAnimate>
    );

};