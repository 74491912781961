import { useContext } from "react";
//
import { NotificationContext } from "./NotificationContext";

// ----------------------------------------------------------------------

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (!context)
    throw new Error(
      "useNotificationContext context must be use inside NotificationProvider"
    );

  return context;
};
