import { Card, Stack, Typography } from "@mui/material";
import { useState } from "react";
import IColorType from "src/theme/new-ui/color.schema";

export interface OptionItem {
  id: any;
  title: string;
  thumbnail: any;
}

interface Props {
  option: OptionItem;
  cardWidth?: number;
  cardHeight?: number;
  shadowHeight?: number;
  borderRadius?: number;
  maxImageWidth: number;
  imgTextGap: number;
  fontSize: number;
  selected: boolean;
  colorType: IColorType;
  onSelected: (option: OptionItem) => void;
}

const ANIM_DURATION_MS = 90;

const PressableOptionItem = ({
  cardWidth = 80,
  cardHeight = 80,
  shadowHeight = 4,
  borderRadius = 16,
  maxImageWidth,
  fontSize,
  imgTextGap,
  option,
  selected,
  colorType,
  onSelected,
}: Props) => {
  const [marginTop, setMarginTop] = useState(0);
  const [height, setHeight] = useState(cardHeight + shadowHeight);

  const handlePress = () => {
    setMarginTop(shadowHeight);
    setHeight(cardHeight);
    setTimeout(() => {
      setMarginTop(0);
      setHeight(cardHeight + shadowHeight);
      onSelected(option);
    }, ANIM_DURATION_MS);
  };

  return (
    <Card
      sx={[
        {
          boxShadow: 3,
          marginTop: `${marginTop}px`,
          height: `${height}px`,
          transition: `margin-top ${ANIM_DURATION_MS}ms linear 0s, height ${ANIM_DURATION_MS}ms linear 0s`,
          width: `${cardWidth}px`,
          left: "0px",
          top: "0px",
          borderRadius: `${borderRadius}px`,
          backgroundColor: `${colorType.shadow}`,
        },
      ]}
    >
      <Card
        sx={[
          {
            boxShadow: 0,
            border: `1px solid`,
            borderColor: `${colorType.stroke}`,
            height: `${cardHeight}px`,
            width: `${cardWidth}px`,
            left: "0px",
            top: "0px",
            borderRadius: `${borderRadius}px`,
          },
        ]}
      >
        <Stack
          key={`${option.id}`}
          direction={"column"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => handlePress()}
        >
          <img
            src={`${option.thumbnail}`}
            style={{
              maxWidth: maxImageWidth,
            }}
          />
          <Typography fontSize={`${fontSize}px`} marginTop={`${imgTextGap}px`}>
            {option.title}
          </Typography>
        </Stack>
      </Card>
    </Card>
  );
};

export default PressableOptionItem;
