// @mui
import { BoxProps, Grid } from "@mui/material";
import { Stack } from "@mui/system";
// @type
import { IProduct } from "../../../../@types/product";
// components
import { SkeletonProductItem } from "../../../../components/skeleton";
import ShopProductCardVariant1 from "./ShopProductCardVariant1";
import CarouselCenterMode from "./CarouselCenterMode";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  products: Array<IProduct[]>;
  loading: boolean;
}

export default function ShopProductListVariant1({ products, loading }: Props) {
  if (loading) {
    return (
      <>
        {[...Array(9)].map((_, index) => (
          <SkeletonProductItem key={index} />
        ))}
      </>
    );
  }

  return (
    <>
      <Stack spacing={1} direction={"column"}>
        {products.map((data, index) => {
          return (
            <Grid container xs={12} alignItems={"center"}>
              {/* <Grid item xs={2} marginBottom={2}>
                <Typography
                  fontSize={"12px"}
                  fontWeight={"700"}
                  color={"#454F5B"}
                  minWidth={"40px"}
                  height={"10px"}
                >
                  {displayTexts?.products.trayWithIndex.replace(
                    "{{index}}",
                    `${index + 1}`
                  )}
                </Typography>
              </Grid> */}
              <Grid item xs={12}>
                <CarouselCenterMode
                  data={data}
                  index={index}
                  key={index}
                  ShopProductCardComponent={ShopProductCardVariant1}
                />
              </Grid>
            </Grid>
          );
        })}
      </Stack>
    </>
  );
}
