import { Dialog, DialogProps, Paper } from "@mui/material";
import { useState } from "react";
import IColorType from "src/theme/new-ui/color.schema";
import { COLOR_GRAY_SHADOW } from "src/theme/new-ui/component.color";

export interface Props extends DialogProps {
  onClose?: VoidFunction;
  bgcolor?: string;
  radius?: number;
  shadowHeight?: number;
}

export default function PressableDialog({
  bgcolor = COLOR_GRAY_SHADOW,
  radius = 24,
  shadowHeight = 10,
  open = false,
  children,
  onClose,
  ...other
}: Props) {
  const [showDialog, setShowDialog] = useState(open);

  return (
    <Dialog
      open={showDialog}
      fullWidth
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: bgcolor,
          borderRadius: `${radius}px`,
          overflow: "hidden",
          // clipPath: "inset(0 0 0 0 round 16px)",
        },
      }}
    >
      <Paper
        sx={{
          boxShadow: 0,
          borderRadius: `${radius}px`,
          marginBottom: `${shadowHeight}px`,
        }}
      >
        {children}
      </Paper>
    </Dialog>
  );
}
