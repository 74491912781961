import {
  cash,
  link,
  paytm,
  rfid,
  swift,
  fonepay,
} from "../assets/paymentMethods";
import { ICheckoutCardOption, ICheckoutPaymentOption } from "../@types/product";

export const PAYMENT_METHODS: {
  PAYTM: string;
  LINK: string;
  RFID: string;
  SWIFT: string;
  CASH: string;
  FONEPAY: string;
} = {
  PAYTM: "paytm",
  LINK: "link",
  RFID: "rfid",
  SWIFT: "swift",
  CASH: "cash",
  FONEPAY: "fonepay",
};

export const PAYMENT_OPTION_IMAGES: { [index: string]: string } = {
  paytm: paytm,
  link: link,
  rfid: rfid,
  swift: swift,
  cash: cash,
  fonepay: fonepay,
};

export const PAYMENT_OPTIONS: ICheckoutPaymentOption[] = [
  {
    value: PAYMENT_METHODS.PAYTM,
    title: "Pay with PAYTM",
    description: "",
    icon: paytm,
  },
  {
    value: PAYMENT_METHODS.LINK,
    title: "Pay with LINK",
    description: "",
    icon: link,
  },
  {
    value: PAYMENT_METHODS.RFID,
    title: "Pay with RFID",
    description: "",
    icon: rfid,
  },
  {
    value: PAYMENT_METHODS.SWIFT,
    title: "Pay with SWIFT",
    description: "",
    icon: swift,
  },
  {
    value: PAYMENT_METHODS.CASH,
    title: "Pay with CASH",
    description: "",
    icon: cash,
  },
  {
    value: PAYMENT_METHODS.FONEPAY,
    title: "Pay with FONEPAY",
    description: "",
    icon: fonepay,
  },
];
