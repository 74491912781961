import { Box, Paper } from "@mui/material";
import React from "react";
import generateAlpha from "src/theme/new-ui/color.utils";
import { COLOR_GRAY } from "src/theme/new-ui/component.color";

interface Props {
  marginTop?: number;
  shadowHeight?: number;
  borderRadiusOuter?: number;
  borderRadiusInner?: number;
  children: React.ReactNode;
}

export default function BorderedPushButton({
  marginTop = 3,
  shadowHeight = 20,
  borderRadiusOuter = 26,
  borderRadiusInner = 16,
  children,
}: Props) {
  return (
    <Paper
      elevation={0}
      sx={{
        marginTop: marginTop,
        width: "100%",
        borderRadius: `${borderRadiusOuter}px`,
        paddingBottom: `${shadowHeight - 12}px`,
        backgroundColor: `${generateAlpha(COLOR_GRAY, 0.7)}`,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: `${borderRadiusOuter}px`,
          /* border: "0.01px solid", */
          borderColor: `${generateAlpha(COLOR_GRAY, 0.7)}`,
          padding: `${shadowHeight}px`,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            borderRadius: `${borderRadiusInner}px`,
            backgroundColor: `${generateAlpha(COLOR_GRAY, 0.7)}`,
            paddingTop: `${shadowHeight / 2}px`,
            paddingLeft: `${shadowHeight / 4}px`,
            paddingRight: `${shadowHeight / 4}px`,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: `${borderRadiusInner}px`,
              backgroundColor: "#DFE3E8",
              padding: `${shadowHeight / 1.5}px`,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                borderRadius: `${borderRadiusInner}px`,
                paddingBottom: `${shadowHeight - 12}px`,
                backgroundColor: `${generateAlpha(COLOR_GRAY, 0.7)}`,
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  borderRadius: `${borderRadiusInner}px`,
                  padding: "15px",
                }}
              >
                {children}
              </Paper>
            </Paper>
          </Paper>
        </Paper>
      </Paper>
    </Paper>
  );
}
