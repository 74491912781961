// @mui
import {
  Card,
  Stack,
  Button,
  Divider,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
// routes
// utils
import { fCurrency } from "../../../../utils/formatNumber";
// redux
import { useDispatch, useSelector } from "../../../../redux/store";
import {
  addToCart,
  decreaseQuantity,
  increaseQuantity,
} from "../../../../redux/slices/product";
// @types
import { IProduct } from "../../../../@types/product";
// components
import Iconify from "../../../../components/iconify";
import Image from "../../../../components/image";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { useMemo, useRef } from "react";
import PressableCard from "src/components/pressables/PressableCard";
import {
  COLOR_PRIMARY,
  COLOR_TEXT_ON_WHITE,
  COLOR_TEXT_ON_WHITE_LIGHT,
  COLOR_WHITE,
  CardPrimaryColor,
  CardPrimaryUnSelectedColor,
} from "src/theme/new-ui/component.color";
import { getCurrentLanguage } from "src/i18n";
import { useSnackbar } from "notistack";
import { useNotificationContext } from "src/context/notification/useNotificationContext";
// import AspectRatio from "@mui/joy/AspectRatio";

// ----------------------------------------------------------------------

const DIMEN_WIDTH = 220;
const DIMEN_HEIGHT = 280;

type Props = {
  product: IProduct;
};

export default function ShopProductCardVariant2({ product }: Props) {
  const {
    id,
    name,
    price,
    image,
    pos,
    product_id,
    enabled,
    actual_price,
    image_mini,
    total_units,
    left_units,
    show_pos,
    expiry_date,
    description,
    row,
    custom_css,
  } = product;

  const { maxItems, currencyDetails } = useMachineConfigurationContext();
  const dispatch = useDispatch();
  const notification = useNotificationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  //const { checkout } = useSelector((state) => state.product);
  const { totalItems, cart } = useSelector((state) => state.product.checkout);

  const productQuantity = useMemo(() => {
    if (cart.length <= 0) return 0;
    let quantity = 0;
    cart.forEach((product) => {
      if (product.id === id) quantity = product.quantity;
    });
    return quantity;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const handleIncreaseQuantity = () => {
    if (totalItems === maxItems) {
      notification.notify("Cart full!", null, {
        height: 80,
        radius: 32,
        marginTop: 20,
        maxWidth: 800,
        shadowHeight: 12,
        padding: 2,
        fontSize: 32,
      });
      return;
    }
    handlePress(() => dispatch(increaseQuantity(id)));
  };

  const handleDecreaseQuantity = () => {
    handlePress(() => dispatch(decreaseQuantity(id)));
  };

  const handleAddCart = async () => {
    if (totalItems === maxItems) {
      notification.notify("Cart full!", null, {
        height: 80,
        radius: 32,
        marginTop: 20,
        maxWidth: 800,
        shadowHeight: 12,
        padding: 2,
        fontSize: 32,
      });
      return;
    }
    handlePress(() =>
      dispatch(
        addToCart({
          id,
          name,
          image,
          price,
          pos,
          product_id,
          enabled,
          actual_price,
          image_mini,
          total_units,
          left_units: left_units - 1,
          show_pos,
          expiry_date,
          description,
          row,
          custom_css,
          quantity: 1,
        })
      )
    );
  };

  const pressableCardRef = useRef<any>(null);
  const handlePress = (onEnd: () => void) => {
    if (pressableCardRef.current) {
      (pressableCardRef.current as any).handlePress(onEnd);
    }
  };

  return (
    <PressableCard
      cardWidth={DIMEN_WIDTH}
      cardHeight={DIMEN_HEIGHT}
      shadowHeight={6}
      ref={pressableCardRef}
      colorType={
        left_units <= 0 ? CardPrimaryUnSelectedColor : CardPrimaryColor
      }
    >
      <Box position={"relative"}>
        <Stack
          sx={{
            position: "fixed",
            zIndex: 0,
          }}
          width={DIMEN_WIDTH}
          height={DIMEN_HEIGHT}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Card
            sx={{
              marginTop: 1.5,
              boxShadow: 0,
              borderRadius: "16px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={image} style={{ height: 140 }} />
          </Card>

          <Stack direction={"column"} spacing={0} alignItems={"center"}>
            <Typography
              padding={0}
              fontSize={"20px"}
              fontWeight={500}
              color={"#637381"}
              maxWidth={DIMEN_WIDTH - 32}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                textTransform: "capitalize",
              }}
            >
              {name}
            </Typography>

            <Stack
              direction={"row"}
              width={DIMEN_WIDTH - 32}
              alignItems={"center"}
              justifyContent={"space-between"}
              padding={0}
            >
              <Stack
                direction={"row"}
                alignItems={"baseline"}
                spacing={0.4}
                padding={0}
              >
                <Typography
                  padding={0}
                  fontSize={"24px"}
                  fontWeight={800}
                  color={COLOR_TEXT_ON_WHITE_LIGHT}
                >
                  {currencyDetails?.symbol}
                </Typography>
                <Typography
                  padding={0}
                  fontSize={"32px"}
                  fontWeight={800}
                  color={COLOR_TEXT_ON_WHITE_LIGHT}
                >
                  {price}
                </Typography>
              </Stack>

              {productQuantity != 0 ? (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    bgcolor: COLOR_PRIMARY,
                    borderRadius: "6px",
                    minWidth: "110px",
                  }}
                >
                  <IconButton
                    onClick={handleDecreaseQuantity}
                    size="medium"
                    sx={{ color: "white", marginLeft: "0px" }}
                  >
                    <Iconify icon="eva:minus-fill" width={20} />
                  </IconButton>
                  <Typography
                    fontSize={"28px"}
                    fontWeight={"800"}
                    color={COLOR_WHITE}
                  >
                    {productQuantity}
                  </Typography>
                  <IconButton
                    onClick={handleIncreaseQuantity}
                    disabled={productQuantity >= total_units}
                    size="medium"
                    sx={{ color: "white", marginRight: "0px" }}
                  >
                    <Iconify icon="eva:plus-fill" width={20} />
                  </IconButton>
                </Stack>
              ) : (
                <Button
                  sx={{
                    color: COLOR_PRIMARY,
                    fontWeight: "700",
                    fontSize: "28px",
                    padding: 0,
                  }}
                  onClick={() => {
                    if (left_units > 0) handleAddCart();
                  }}
                  disabled={left_units <= 0}
                >
                  {displayTexts?.products.addProductToCart}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Card
          sx={{
            position: "fixed",
            zIndex: 1,
            borderRadius: "16px 0px",
            width: "90px",
            boxShadow: 0.1,
          }}
        >
          <Typography
            fontSize={"28px"}
            fontWeight={800}
            color={COLOR_PRIMARY}
            align="center"
          >
            {show_pos}
          </Typography>
        </Card>
      </Box>
    </PressableCard>
  );
}
