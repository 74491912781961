import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, useState, useMemo } from "react";
import { Container, Box } from "@mui/material";
import { useDispatch, useSelector } from "../../../redux/store";
import {
  initOrderCycle,
  createPaymentMethod,
} from "../../../redux/slices/product";
import { setProducts } from "../../../redux/slices/product";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useSettingsContext } from "../../../components/settings";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { OrderService } from "src/services";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import ShopProductListVariant1 from "src/sections/@dashboard/e-commerce/shop/ShopProductListVariant1";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant1/PageHeaderCrumb";
import PageFooterAction from "../../../sections/@dashboard/crumbs/variant1/PageFooterActions";
import { getCurrentLanguage } from "src/i18n";
import OverlayLoading from "src/components/overlay-loading/OverlayLoading";

export default function EcommerceShopPageVariant1() {
  console.log("EcommerceShopPageVariant1");
  const [isLoading, setIsLoading] = useState(false);
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { products: _products } = useMachineConfigurationContext();
  const { products: reduxProducts } = useSelector((state) => state.product);
  const { cart } = useSelector((state) => state.product.checkout);

  useEffect(() => {
    dispatch(setProducts(_products));
  }, [dispatch, _products]);

  const products = useMemo(() => reduxProducts, [reduxProducts]);

  const handelConfirmCheckout = async () => {
    try {
      setIsLoading(true);

      if (!isLoading) {
        // Use setTimeout to allow the state update to happen before the API call
        setTimeout(async () => {

          console.log(isLoading);
          dispatch(
            createPaymentMethod({ payment: { value: PAYMENT_METHODS.PAYTM } })
          );
          const data = cart.map((product) => ({
            pos: product.pos,
            quantity: product.quantity,
          }));
          const response = await OrderService.generateRequestId(data);
          console.log(response);

          if (response.error) return;

          dispatch(initOrderCycle({ request_id: response.request_id }));
          setIsLoading(false);

          navigate(PATH_DASHBOARD.eCommerce.checkout, {
            replace: true,
          });
        }, 0);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{displayTexts?.products.helmetTitle}</title>
      </Helmet>
      <>
        <Container maxWidth={false} disableGutters>
          {isLoading && <OverlayLoading />}
          <PageHeaderCrumb
            elevation={10}
            handleHelpPageClick={() => {
              console.log("handleHelpPageClick");
            }}
          />
          <Box sx={{ marginTop: "46px" }}>
            <ShopProductListVariant1 products={products} loading={!products.length} />
          </Box>
          <Box sx={{ marginBottom: "30px" }}>
            <PageFooterAction
              positiveAction={handelConfirmCheckout}
              negativeAction={() => { }}
            />
          </Box>
        </Container>
      </>
    </>
  );
}
