import { createContext, useState } from "react";
import PressableSnackbar, {
  PressableSnackbarProps,
} from "src/components/pressables/PressableSnackbar";

type NotificationContextType = {
  notify: (
    message: string,
    icon?: any,
    otherProps?: PressableSnackbarProps
  ) => void;
};

export const NotificationContext =
  createContext<NotificationContextType | null>(null);

type NotificationProviderProps = {
  children: React.ReactNode;
};

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [icon, setIcon] = useState(null);
  const [otherProps, setOtherProps] = useState<
    PressableSnackbarProps | undefined | null
  >(null);

  const notify = (
    message: string,
    icon: any,
    otherProps?: PressableSnackbarProps
  ) => {
    console.log("NotificationProvider.notify: ", show);
    setShow(true);
    setMessage(message);
    setIcon(icon);
    setOtherProps(otherProps);
  };

  console.log("NotificationProvider: rendering snackbar...", show);

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      {show && (
        <PressableSnackbar
          onClosed={() => setShow(false)}
          open={show}
          message={message}
          icon={icon}
          {...otherProps}
        />
      )}
    </NotificationContext.Provider>
  );
}
