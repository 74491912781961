import { VARIANT } from "src/config";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import EcommercePaymentPageVariant1 from "src/pages/dashboard/payment/EcommercePaymentPageVariant1";
import EcommercePaymentPageVariant2 from "src/pages/dashboard/payment/EcommercePaymentPageVariant2";

function PaymentVariant() {
  const { machineData } = useMachineConfigurationContext();

  //switch (VARIANT) {
  switch (machineData.machine_type) {
    case "big":
      return <EcommercePaymentPageVariant1 />;
    default:
      return <EcommercePaymentPageVariant2 />;
  }
}

export default PaymentVariant;
