import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { KeyboardInput } from "react-simple-keyboard";
import { PaymentLoader } from "src/components/payment-loader";
import Countdown from "src/components/pressables/Countdown";
import PressableButton from "src/components/pressables/PressableButton";
import PressableDialPad from "src/components/pressables/dialpad/PressableDialPad";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import { getCurrentLanguage } from "src/i18n";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { useNotificationContext } from "src/context/notification/useNotificationContext";
import { createPaymentMethod } from "src/redux/slices/product";
import { dispatch, useSelector } from "src/redux/store";
import { OrderService } from "src/services";
import {
  ButtonGrayTextColor,
  COLOR_GRAY,
  COLOR_TEXT_ON_WHITE,
} from "src/theme/new-ui/component.color";
import { fCurrency } from "src/utils/formatNumber";

const TAG = "/new-ui/SwiftPaymentMethod";

interface Props {
  /* timeLeft: number;
  setTimeLeft: (timeLeft: number) => void; */
  cancelOrder: VoidFunction;
  proceedToNextStep: VoidFunction;
  inputs: {
    phoneNo: string;
    otp?: string;
  };
  openErrorDialog: VoidFunction;
  setInputs: Dispatch<
    SetStateAction<{
      phoneNo: string;
      otp?: string;
    }>
  >;
}

function SwiftPaymentMethodVariant1({
  /* timeLeft,
  setTimeLeft, */
  proceedToNextStep,
  cancelOrder,
  inputs,
  setInputs,
}: Props) {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );
  const { currencyDetails } = useMachineConfigurationContext();
  const { checkout } = useSelector((state) => state.product);
  const notification = useNotificationContext();

  const data = useRef({
    request_id: checkout.orderId,
    payment_method: PAYMENT_METHODS.SWIFT,
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isPhoneNoAdded, setIsPhoneNoAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [dialed, setDialed] = useState("");
  const [code, setCode] = useState("+91");

  useEffect(() => {
    setInputs({ phoneNo: phoneNumber, otp: otp });
  }, [phoneNumber, otp]);

  const callCheckoutApi = async () => {
    setIsLoading(true);
    console.log(isLoading, "isLoading");

    const handleResponse = async (response: any) => {
      console.log("response", response);
      setIsLoading(false);
      if (response.error) {
        console.log(response.error);
        notification.notify(response.error);
        return;
      }
      setIsPhoneNoAdded(true);
      setDialed("");
    };

    const handleError = (error: any) => {
      setIsLoading(false);
      console.error(`${TAG}.callCheckoutApi.catch: `, error);
      if (error.message) {
        notification.notify(error.message);
      }
      setIsPhoneNoAdded(false);
    };

    if (!isLoading) {
      setTimeout(async () => {
        try {
          const response = await new Promise(async (resolve, reject) => {
            try {
              const result = await OrderService.checkoutRequestV2({
                ...data.current,
                phone: phoneNumber,
              });
              resolve(result);
            } catch (error) {
              reject(error);
            }
          });

          await handleResponse(response);
        } catch (error) {
          handleError(error);
        } finally {
          setIsLoading(false);
        }
      }, 0);
    }
  };


  const validateSwiftOtp = async () => {
    setIsLoading(true);
    if (isLoading) {
      return;
    }
    setTimeout(async () => {
      const data: {
        request_id: string;
        otp: string;
        phone_number: string;
      } = {
        request_id: checkout.orderId,
        otp: otp,
        phone_number: phoneNumber,
      };
      try {
        const response = await OrderService.authoriseSwiftPayment(data);
        setIsLoading(false);
        if (response.error) {
          console.log(response.error);
          notification.notify(response?.message);
          return;
        }
        proceedToNextStep();
      } catch (error) {
        setIsLoading(false);
        console.error(`${TAG}.validateSwiftOtp.catch: `, error);
      }
    }, 0);
  };

  const resetEverything = () => {
    setIsPhoneNoAdded(false);
    setPhoneNumber("");
    setOtp("");
    setDialed("");
    // setCode(""); // do not reset phone code for now
    // setIsLoading(false);
    // setIsError(false);
  };

  return (
    <div>
      {!isError && isLoading && <CircularProgress color="error" />}
      {!isError && !isLoading && (
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            sx={{
              position: "absolute",
              top: 100,
            }}
            direction={"column"}
            alignItems={"center"}
            spacing={0}
            marginBottom={3}
          >
            <Typography
              fontSize={20}
              fontWeight={700}
              color={COLOR_TEXT_ON_WHITE}
            >
              {isPhoneNoAdded
                ? displayTexts?.payment.swift.enterOtp
                : displayTexts?.payment.swift.enterPhone}
            </Typography>
            <Countdown
              /* seconds={300}
              timeLeft={timeLeft}
              setTimeLeft={setTimeLeft} */
              onTimeout={cancelOrder}
            />
            <Typography
              fontSize={28}
              fontWeight={700}
              color={COLOR_TEXT_ON_WHITE}
            >
              {`${currencyDetails && currencyDetails.isSuffix
                ? ""
                : currencyDetails?.symbol + " "
                }${fCurrency(checkout.total)}${currencyDetails && currencyDetails.isSuffix
                  ? " " + currencyDetails?.symbol
                  : ""
                }`}
            </Typography>
            <PressableDialPad
              dialed={dialed}
              setDialed={setDialed}
              code={code}
              setCode={setCode}
              isPhoneField={!isPhoneNoAdded}
              actionText={`${isPhoneNoAdded
                ? displayTexts?.payment.swift.verify
                : displayTexts?.payment.swift.sendOtp
                }`}
              onDialed={(data0, data1) => {
                console.log("callCheckoutApi: ", data0);
                if (isPhoneNoAdded) setOtp(data0);
                else
                  setPhoneNumber(
                    // data1 ? data1 + data0 : data0
                    data0
                  );
              }}
              onAction={async (data) => {
                if (!isPhoneNoAdded) {
                  // setIsLoading(true);
                  if (phoneNumber.length >= 8) {
                    dispatch(
                      createPaymentMethod({
                        payment: { phoneNo: "91" + phoneNumber },
                      })
                    );
                    callCheckoutApi();
                  } else {
                    notification.notify(displayTexts?.payment.swift.enterValidNumber || "Please enter a valid number")
                  }
                } else {
                  await validateSwiftOtp();
                }
              }}
            />
          </Stack>

          <Stack
            sx={{
              position: "absolute",
              bottom: 20,
            }}
            direction={"column"}
            alignItems={"center"}
            spacing={0}
          >
            <Stack direction={"row"} spacing={1}>
              <PressableButton
                text={displayTexts?.payment.swift.changeNumber}
                buttonWidth={120} // todo: remove this line while removing en. suffix from language file
                onClick={resetEverything}
                colorType={ButtonGrayTextColor}
              />
              <PressableButton
                text={displayTexts?.payment.cancelPayment}
                buttonWidth={120} // todo: remove this line while removing en. suffix from language file
                onClick={cancelOrder}
              />
            </Stack>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={COLOR_GRAY}
              marginTop={"12px"}
            >
              {displayTexts?.payment.orderId.replace(
                "{{orderId}}",
                data.current.request_id
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
      {/* {isError && (
        <Typography variant="body2" gutterBottom align="center" color={"error"}>
          {displayTexts?.payment.swift.accountExists}
        </Typography>
      )} */}
    </div>
  );
}

export default SwiftPaymentMethodVariant1;
