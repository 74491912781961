import {
  Card,
  Snackbar,
  SnackbarProps,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  SnackbarColor,
} from "src/theme/new-ui/component.color";
import Iconify from "../iconify/Iconify";
import IColorType from "src/theme/new-ui/color.schema";

export interface PressableSnackbarProps {
  icon?: any;
  colorType?: IColorType;
  radius?: number;
  marginTop?: number;
  height?: number;
  maxWidth?: number;
  shadowHeight?: number;
  padding?: number;
  fontSize?: number;
}

export interface Props extends SnackbarProps, PressableSnackbarProps {
  onClosed: VoidFunction;
}

export default function PressableSnackbar({
  onClosed,
  open,
  message,
  icon = ErrorIcon,
  colorType = SnackbarColor,
  radius = 16,
  marginTop = 10,
  height = 40,
  maxWidth = 400,
  shadowHeight = 4,
  padding = 1,
  fontSize = 16,
  children,
  ...other
}: Props) {
  console.log("PressableSnackbar: open=", open);

  const handleClose = () => {
    onClosed();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      message={message}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        marginTop: marginTop,
      }}
    >
      <Card
        sx={{
          borderRadius: `${radius}px 0px 0px ${radius}px`,
          height: `${height + shadowHeight}px`,
          backgroundColor: colorType.shadow,
        }}
      >
        <Card
          sx={{
            paddingLeft: 2 * padding,
            paddingRight: 2 * padding,
            paddingTop: padding,
            paddingBottom: padding,
            borderRadius: `${radius}px 0px 0px ${radius}px`,
            height: `${height}px`,
            maxWidth: `${maxWidth}px`,
            boxShadow: 0,
          }}
        >
          <Stack direction={"row"} spacing={2 * padding} alignItems={"center"}>
            <ErrorIcon
              color="error"
              fontSize={`${fontSize <= 20 ? "medium" : "large"}`}
            />
            <Typography
              fontWeight={700}
              fontSize={`${fontSize}px`}
              color={colorType.text}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {message}
            </Typography>
          </Stack>
        </Card>
      </Card>
    </Snackbar>
  );
}
