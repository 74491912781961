import { Box, Button, Card, Typography } from "@mui/material";
import { useState } from "react";
import IColorType from "src/theme/new-ui/color.schema";
import {
  ButtonPrimaryColor,
  ButtonPrimaryDisabledColor,
  ButtonPrimaryTextColor,
} from "src/theme/new-ui/component.color";

interface Props {
  radius?: number;
  emoji: any;
  shadowColor?: string;
  shadowHeight?: number;
  onClick: () => void;
}

const ANIM_DURATION_MS = 100;

export default function PressableFeedback({
  radius = 84,
  emoji,
  shadowColor = "#9E694799",
  shadowHeight = 5,
  onClick,
}: Props) {
  const [marginTop, setMarginTop] = useState(shadowHeight);

  const handlePress = () => {
    setMarginTop(0);
    setTimeout(() => {
      setMarginTop(shadowHeight);
      onClick();
    }, ANIM_DURATION_MS);
  };

  return (
    <div>
      <Box
        sx={{
          width: `${radius}px`,
          height: `${radius}px`,
          borderRadius: "50%",
          backgroundColor: shadowColor,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: `${radius}px`,
            height: `${radius}px`,
            borderRadius: "50%",
            // backgroundColor: "#DFE3E8",
            position: "absolute",
            zIndex: 2,
            marginTop: `-${marginTop}px`,
            transition: `margin-top ${ANIM_DURATION_MS}ms linear 0s`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            handlePress();
          }}
        >
          <img
            src={emoji}
            alt={"test"}
            style={{
              width: `${radius}px`,
            }}
            onClick={() => {
              handlePress();
            }}
          />
        </Box>
      </Box>
    </div>
  );
}
