import { ReactNode, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Grid,
  Container,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
// routes
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import {
  resetCart,
  getCart,
  createPaymentMethod,
} from "../../../redux/slices/product";
// @types
import { ICheckoutPaymentMethod } from "../../../@types/product";
// components

import { useSettingsContext } from "../../../components/settings";
// sections
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { OrderService } from "src/services";
import { Stack } from "@mui/system";

import { PRODUCT_OPTIONS_CONSTANTS } from "../../../constants/index";
import { MAX_PAYMENT_TIMEOUT, PATH_AFTER_LOGIN } from "src/config";
// ---
import MQTTObserver, { ChannelsOrEvents } from "src/observers/mqttObserver";
import PaytmPaymentMethodVariant2 from "src/sections/@dashboard/e-commerce/payment/paytm/PaytmPaymentMethodVariant2";
import SwiftPaymentMethodVariant2 from "src/sections/@dashboard/e-commerce/payment/swift/SwiftPaymentMethodVariant2";
import CashPaymentMethodVariant2 from "src/sections/@dashboard/e-commerce/payment/cash/CashPaymentMethodVariant2";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant2/PageHeaderCrumb";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant2/PageFooterCrumb";
import PressableTabGroup from "src/components/pressables/tab/PressableTabGroup";
import PressableOptionGroup from "src/components/pressables/option/PressableOptionGroup";
import { OptionItem } from "src/components/pressables/option/PressableOptionItem";
import { getCurrentLanguage } from "src/i18n";
import { TableHeadCustom } from "src/components/table";
import { fCurrency } from "src/utils/formatNumber";
import CheckoutOrderComplete from "src/sections/@dashboard/e-commerce/checkout/CheckoutOrderComplete";
import ErrorDialog from "src/sections/@dashboard/e-commerce/ErrorDialog";
import DialogCancelPayment from "src/sections/dialogs/CancelPaymentDialog";
import { PaymentTimerProvider } from "src/context/PaymentTimerContext/PaymentTimer.context";

// ----------------------------------------------------------------------

let checkStatusIntervalId: ReturnType<typeof setInterval>;

export default function EcommercePaymentPageVariant2() {
  console.log("EcommercePaymentPageVariant2");
  const navigate = useNavigate();
  const mqttObserverInstance = MQTTObserver.getInstance();
  const dispatch = useDispatch();
  const { checkout } = useSelector((state) => state.product);
  const { maxItems, enabledPaymentGateways } = useMachineConfigurationContext();
  const { themeStretch } = useSettingsContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const [paymentOptions, setPaymentOptions] = useState(Array<OptionItem>);

  const [inputs, setInputs] = useState<{ phoneNo: string; otp?: string }>({
    phoneNo: "",
    otp: "",
  });
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCancellingPayment, setIsCancellingPayment] = useState(false);

  // const subscriptionId = useRef<string>("");
  const subscriptionIdList = useRef<Array<string>>([]);
  const [isMachineReady, setisMachineReady] = useState<Boolean>(false);

  const [timeLeft, setTimeLeft] = useState(MAX_PAYMENT_TIMEOUT);

  const initResponseHandler = (data: any) => {
    console.log("initResponseHandler :: ", data);
    if (data.status) setisMachineReady(true);
  };

  const handleTabChange = (position: number) => {
    setSelectedTab(position);
  };

  const closeErrorDialog = () => {
    setIsErrorOccurred(false);
  };

  const openErrorDialog = () => {
    setIsErrorOccurred(true);
  };

  const { cart, activeStep, totalItems, orderId, total, subtotal, payment } =
    checkout;

  const handleReset = () => {
    if (isPaymentCompleted) {
      dispatch(resetCart());
      navigate(PATH_AFTER_LOGIN, { replace: true });
    }
  };

  const handleCancel = async () => {
    try {
      const response = await OrderService.cacelOrder({ request_id: orderId });
      if (response.error) return;
      dispatch(resetCart());
      navigate(PATH_AFTER_LOGIN, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const cancelOrder = async () => {
    setIsCancellingPayment(true);
  };

  const cancelOrderImpl = async () => {
    clearAllSubscriptions();
    try {
      const response = await OrderService.cacelOrder({ request_id: orderId });
      if (response.error) return;
      dispatch(resetCart());
      closeErrorDialog();
      navigate(PATH_AFTER_LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  const resetPayment = () => {
    dispatch(
      createPaymentMethod({
        payment: { phoneNo: "" },
      })
    );
  };

  const proceedToNextStep = () => {
    console.log("proceedToNextStep called");
    setIsPaymentCompleted(true);
  };

  const handelPaymentComplete = (data: any) => {
    if (data.status) proceedToNextStep();
  };

  const clearAllSubscriptions = () => {
    clearInterval(checkStatusIntervalId);
    // mqttObserverInstance.unsubscribe(subscriptionId.current);
    mqttObserverInstance.unsubscribeMultiple(subscriptionIdList.current);
  };

  const subscriptionId = useRef<string>("");

  useEffect(() => {
    dispatch(getCart(cart));
  }, [dispatch, cart]);

  useEffect(() => {
    // subscriptionId.current = mqttObserverInstance.subscribe(
    //   ChannelsOrEvents.PAYMENT_COMPLETE,
    //   handelPaymentComplete
    // );
    subscriptionIdList.current.push(
      mqttObserverInstance.subscribe(
        ChannelsOrEvents.PAYMENT_COMPLETE,
        handelPaymentComplete
      )
    );
    subscriptionIdList.current.push(
      mqttObserverInstance.subscribe(
        ChannelsOrEvents.INIT_RESPONSE,
        initResponseHandler
      )
    );
    return clearAllSubscriptions;
  }, []);

  const getPaymentMethodDetails = (
    payment: ICheckoutPaymentMethod
  ): ReactNode => {
    switch (payment.value) {
      case PRODUCT_OPTIONS_CONSTANTS.PAYMENT_METHODS.PAYTM:
        return (
          <PaytmPaymentMethodVariant2
            cancelOrder={cancelOrder}
            proceedToNextStep={proceedToNextStep}
            openErrorDialog={openErrorDialog}
          />
        );
      case PRODUCT_OPTIONS_CONSTANTS.PAYMENT_METHODS.SWIFT:
        return (
          <SwiftPaymentMethodVariant2
            proceedToNextStep={proceedToNextStep}
            cancelOrder={cancelOrder}
            inputs={inputs}
            setInputs={setInputs}
            openErrorDialog={openErrorDialog}
          />
        );
      case PRODUCT_OPTIONS_CONSTANTS.PAYMENT_METHODS.CASH:
        return (
          <CashPaymentMethodVariant2
            cancelOrder={cancelOrder}
            proceedToNextStep={proceedToNextStep}
            inputs={inputs}
            setInputs={setInputs}
            openErrorDialog={openErrorDialog}
          />
        );
      case PRODUCT_OPTIONS_CONSTANTS.PAYMENT_METHODS.FONEPAY:
        return <>FONEPAY</>;
      case PRODUCT_OPTIONS_CONSTANTS.PAYMENT_METHODS.LINK:
        return <>LINK</>;
      case PRODUCT_OPTIONS_CONSTANTS.PAYMENT_METHODS.RFID:
        return <>RFID</>;
      default:
        return <>Please select a payment method!</>;
    }
  };

  useEffect(() => {
    let po: Array<OptionItem> = [];
    Object.keys(PRODUCT_OPTIONS_CONSTANTS.PAYMENT_OPTION_IMAGES).forEach(
      (method: string, index: number) => {
        if (enabledPaymentGateways.includes(method)) {
          po.push({
            id: index,
            title: method,
            thumbnail: `${PRODUCT_OPTIONS_CONSTANTS.PAYMENT_OPTION_IMAGES[method]}`,
          });
        }
      }
    );
    setPaymentOptions(po);
  }, [PRODUCT_OPTIONS_CONSTANTS.PAYMENT_OPTION_IMAGES]);

  return (
    <>
      <Helmet>
        <title>
          {selectedTab == 0
            ? displayTexts?.payment.helmetTitle
            : displayTexts?.cart.helmetTitle}
        </title>
      </Helmet>
      <Container maxWidth={false}>
        <PageHeaderCrumb
          elevation={0}
          handleHelpPageClick={() => {
            console.log("handleHelpPageClick");
          }}
        />
        <PressableTabGroup
          tabs={[
            { tabTitle: displayTexts?.payment.payment },
            { tabTitle: displayTexts?.cart.cart },
          ]}
          borderRadius={30}
          marginTop={80}
          tabHeight={64}
          shadowHeight={12}
          fontSize={24}
          current={0}
          onChange={(position) => {
            setSelectedTab(position);
          }}
        />
        <Box marginTop={"30px"}>
          {selectedTab == 0 && (
            <Grid container height={"100%"}>
              <Grid item height={"100%"} xs={3}>
                <PressableOptionGroup
                  cardWidth={160}
                  cardHeight={160}
                  shadowHeight={8}
                  borderRadius={30}
                  maxImageWidth={100}
                  fontSize={24}
                  marginTop={4}
                  spacing={2}
                  options={paymentOptions}
                  selected={paymentOptions.find((option) => {
                    return option.title == payment.value;
                  })}
                  onSelected={(option) => {
                    resetPayment();
                    if (option.title !== payment.value)
                      dispatch(
                        createPaymentMethod({
                          payment: { value: option.title },
                        })
                      );
                  }}
                />
              </Grid>
              <Grid
                item
                height={"80vh"}
                xs={9}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  display="column"
                  sx={{
                    width: "100%",
                  }}
                  spacing={1}
                >
                  <PaymentTimerProvider>
                    {getPaymentMethodDetails(payment)}
                  </PaymentTimerProvider>
                </Stack>
              </Grid>
            </Grid>
          )}
          {selectedTab == 1 && (
            <Table>
              <TableHeadCustom
                headLabel={[
                  {
                    id: "",
                    label: displayTexts?.cart.productCoil,
                    align: "left",
                    fontSize: "28px",
                  },
                  {
                    id: "",
                    label: displayTexts?.cart.price,
                    align: "center",
                    fontSize: "28px",
                  },
                  {
                    id: "",
                    label: displayTexts?.cart.total,
                    align: "right",
                    fontSize: "28px",
                  },
                ]}
              />
              {checkout.cart.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="left">
                      <Typography
                        fontSize={36}
                        fontWeight={700}
                        color={"#454F5B"}
                      >
                        {item.show_pos}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        fontSize={32}
                        fontWeight={600}
                        color={"#454F5B"}
                      >
                        {`₹ ${fCurrency(item.price)} x ${item.quantity}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        fontSize={36}
                        fontWeight={700}
                        color={"#454F5B"}
                      >
                        {`₹ ${fCurrency(item.price * item.quantity)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell align="left" rowSpan={3} colSpan={2}>
                  <Typography fontSize={32} fontWeight={600} color={"#454F5B"}>
                    {displayTexts?.cart.total}
                  </Typography>
                </TableCell>
                <TableCell align="right" rowSpan={3}>
                  <Typography fontSize={64} fontWeight={700} color={"#454F5B"}>
                    {`₹ ${fCurrency(checkout.total)}`}
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
          )}
        </Box>
        <Box sx={{ marginBottom: "60px" }}>
          <PageFooterCrumb elevation={10} />
        </Box>

        {isPaymentCompleted ? (
          <CheckoutOrderComplete
            open={isPaymentCompleted}
            onReset={handleReset}
            onDownloadPDF={() => {}}
            isMachineReady={isMachineReady}
          />
        ) : null}

        {isErrorOccurred ? (
          <ErrorDialog open={isErrorOccurred} cancelOrder={cancelOrder} />
        ) : null}

        {isCancellingPayment && (
          <DialogCancelPayment
            marginScale={2}
            dialogRadius={48}
            dialogShadow={20}
            titleSize={48}
            subtitleSize={32}
            actionSize={24}
            actionHeight={64}
            actionShadow={8}
            actionRadius={32}
            open={isCancellingPayment}
            onContinue={() => setIsCancellingPayment(false)}
            onCancel={cancelOrderImpl}
          />
        )}
      </Container>
    </>
  );
}
