import en from "./en";

const LangConsts = {
  ENGLISH: "en",
  FRENCH: "fr",
};

export const getCurrentLanguage = (language: string) => {
  if (language == LangConsts.ENGLISH) return en;
};

export default LangConsts;
