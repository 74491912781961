// @mui
import { BoxProps, Stack } from "@mui/material";
// @type
import { IProduct } from "../../../../@types/product";
// components
import { SkeletonProductItem } from "../../../../components/skeleton";
//
import ShopProductCardVariant2 from "./ShopProductCardVariant2";
import CarouselCenterMode from "./CarouselCenterMode";

interface Props extends BoxProps {
  products: Array<IProduct[]>;
  loading: boolean;
}

export default function ShopProductListVariant2({ products, loading }: Props) {
  if (loading) {
    return (
      <>
        {[...Array(1)].map((item, index) => (
          <SkeletonProductItem key={index} />
        ))}
      </>
    );
  }

  return (
    <Stack spacing={2} direction={"column"}>
      {products.map((data, index) => {
        return (
          <CarouselCenterMode
            spacing={2}
            data={data}
            key={index}
            index={index}
            ShopProductCardComponent={ShopProductCardVariant2}
          />
        );
      })}
      <div style={{ height: "90px" }} />
    </Stack>
  );
}
