import { createContext, useEffect, useReducer, useCallback } from "react";
import { isValidToken, setSession } from "./utils";
import { ActionMapType, AuthStateType, JWTContextType } from "./types";
import { AuthService, MachineConfigService, MQTTService } from "src/services";

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    machineId: string;
  };
  [Types.LOGIN]: {
    machineId: string;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  machineId: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      machineId: action.payload.machineId,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      machineId: action.payload.machineId,
    };
  }
  if (action.type === Types.LOGOUT) {
    MQTTService.MQTTService.getInstance()?.disconnect();
    return {
      ...state,
      isAuthenticated: false,
      machineId: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : "";

      if (accessToken /* && isValidToken(accessToken) */) {
        setSession(accessToken);

        //TODO: Get Token from localhost
        //const response = await MachineConfigService.machineValidate();

        /* const { user } = response.data;
         */
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            machineId: "0",
          },
        });
      } else {
        //setSession(null);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            machineId: "0",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          machineId: "0",
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (machineId: string, password: string) => {
    const response: any = await AuthService.machineLogin(machineId, password);
    if (response?.error) return;

    const { token: accessToken } = response.data;

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        machineId,
      },
    });
  };

  // LOGOUT
  const logout = async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        user: null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
