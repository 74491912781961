import { Box, Card } from "@mui/material";
import React, { useState, Ref, forwardRef, useImperativeHandle } from "react";
import IColorType from "src/theme/new-ui/color.schema";
import { CardPrimaryColor } from "src/theme/new-ui/component.color";

interface Props {
  cardWidth?: number | string;
  cardHeight?: number;
  shadowHeight?: number;
  borderRadius?: number;
  colorType?: IColorType;
  hasStroke?: boolean;
  strokeWidth?: number;
  children: React.ReactNode;
  forwardedRef?: Ref<HTMLDivElement>;
}

interface RefProps {
  handlePress: (onEnd?: (() => void) | undefined | null) => void;
}

const ANIM_DURATION_MS = 90;

const PressableCard = (
  {
    cardWidth = 90,
    cardHeight = 90,
    shadowHeight = 6,
    borderRadius = 16,
    colorType = CardPrimaryColor,
    hasStroke = false,
    strokeWidth = 1,
    children,
    forwardedRef,
  }: Props,
  ref: any
) => {
  const [marginTop, setMarginTop] = useState(0);
  const [height, setHeight] = useState(cardHeight + shadowHeight);

  useImperativeHandle(ref, () => ({
    handlePress: (onEnd: (() => void) | undefined | null = null) => {
      setMarginTop(shadowHeight);
      setHeight(cardHeight);
      setTimeout(() => {
        setMarginTop(0);
        setHeight(cardHeight + shadowHeight);
        if (onEnd) onEnd();
      }, ANIM_DURATION_MS);
    },
  }));

  return (
    <div
      ref={forwardedRef || ref}
      style={{
        marginTop: "2px",
      }}
    >
      <Card
        sx={[
          {
            boxShadow: 3,
            marginTop: `${marginTop}px`,
            height: `${height}px`,
            transition: `margin-top ${ANIM_DURATION_MS}ms linear 0s, height ${ANIM_DURATION_MS}ms linear 0s`,
            width: typeof cardWidth == "string" ? cardWidth : `${cardWidth}px`,
            left: "0px",
            top: "0px",
            borderRadius: `${borderRadius}px`,
            backgroundColor: colorType.shadow,
          },
        ]}
      >
        <Card
          sx={[
            {
              boxShadow: 0,
              height: `${cardHeight}px`,
              width:
                typeof cardWidth == "string" ? cardWidth : `${cardWidth}px`,
              left: "0px",
              top: "0px",
              borderRadius: `${borderRadius}px`,
              border: `${hasStroke ? strokeWidth : 0}px solid`,
              borderColor: colorType.stroke,
            },
          ]}
        >
          {children}
        </Card>
      </Card>
    </div>
  );
};

export default forwardRef<RefProps, Props>(PressableCard);
