import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
// @mui
import { Container, Typography, Box, Stack } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { resetCart } from "../../../redux/slices/product";
// @types
// components

import { useSettingsContext } from "../../../components/settings";
// sections
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import MQTTObserver, { ChannelsOrEvents } from "src/observers/mqttObserver";
import { nextVendingItem, setVendingStatus } from "src/redux/slices/vending";
import { IVendingItem } from "src/@types/vending";
import { getCurrentLanguage } from "src/i18n";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant1/PageHeaderCrumb";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant1/PageFooterCrumb";
import { COLOR_GRAY, COLOR_WHITE } from "src/theme/new-ui/component.color";
import VendingItem from "./VendingItem";

// ----------------------------------------------------------------------

const STEPS = ["Vending Status", "Feedback"];

// ----------------------------------------------------------------------

interface SItem {
  show_pos: number;
  product_name: string;
  vend_status: string | boolean;
}

const VendingItemWidth = "88px";
const VendLoadingGif = "/assets/configAssets/vending_loading.gif";
const VendSuccessGif = "/assets/configAssets/vending_success.gif";
const VendFaildGif = "/assets/configAssets/vending_failed.gif";

const vendedItems: Array<string> = [];

export default function EcommerceVendingPageVariant1() {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { checkout } = useSelector((state) => state.product);
  const { vendingItems, currentItemIndex } = useSelector(
    (state) => state.vending
  );

  const stacks: IVendingItem[][] = [];
  console.log(stacks)
  vendingItems.map((item, index) => {
    let p = Math.floor(index / 3);
    console.log("stacks.p", p);
    if (p >= stacks.length) {
      let n = [];
      n.push(item);
      stacks.push(n);
    } else {
      stacks[p].push(item);
    }
  });

  return (
    <>
      <Helmet>
        <title>{displayTexts?.vend.helmetTitle}</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
        }}
      >
        <PageHeaderCrumb
          elevation={10}
          handleHelpPageClick={() => {
            console.log("handleHelpPageClick");
          }}
        />

        <Box
          sx={{
            flexGrow: 1,
            marginTop: "40px",
          }}
        >
          {stacks.map((s, indexS) => {
            return (
              <>
                <Stack direction={"row"} justifyContent={"space-around"}>
                  {s.map((item, indexI) => {
                    console.log("items: ", item, indexI, currentItemIndex);
                    if (item.vend_status === "started" && currentItemIndex !== indexI) {
                      // pending
                      return (
                        <img
                          src="/assets/configAssets/VEND_QUEUE_STILL.png"
                          alt="Vend in queue"
                          width={"80px"}
                        />
                      );
                    } else if (currentItemIndex === indexI) {
                      // currently vending
                      return (
                        <img
                          src="/assets/configAssets/vending_loading.gif"
                          alt="Vend in queue"
                          width={"80px"}
                        />
                      );
                    } else if (item.vend_status) {
                      // success
                      if (vendedItems.includes(item.bill_item_id)) {
                        return (
                          <img
                            src="/assets/configAssets/VEND_SUCCESS_STILL.png"
                            alt="Vend success"
                            width={"80px"}
                          />
                        );
                      } else {
                        vendedItems.push(item.bill_item_id);
                        return (
                          <img
                            src="/assets/configAssets/vending_success.gif"
                            alt="Vend success"
                            width={"80px"}
                          />
                        );
                      }
                    } else {
                      // failed
                      if (vendedItems.includes(item.bill_item_id)) {
                        return (
                          <img
                            src="/assets/configAssets/VEND_FAIL_STILL.png"
                            alt="Vend failed"
                            width={"80px"}
                          />
                        );
                      } else {
                        vendedItems.push(item.bill_item_id);
                        return (
                          <img
                            src="/assets/configAssets/vending_failed.gif"
                            width={"80px"}
                            alt="Vend failed"
                          />
                        );
                      }
                    }
                  })}
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  sx={{ backgroundColor: COLOR_GRAY }}
                >
                  {s.map((item, indexI) => {
                    return (
                      <Typography
                        fontSize={18}
                        fontWeight={700}
                        color={COLOR_WHITE}
                      >
                        {item.show_pos}
                      </Typography>
                    );
                  })}
                </Stack>
              </>
            );
          })}
        </Box>

        <Box
          sx={{
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            position={"fixed"}
            sx={{ bottom: "30px" }}
            fontSize={14}
            fontWeight={600}
            color={COLOR_GRAY}
          >
            {displayTexts?.payment.orderId.replace(
              "{{orderId}}",
              checkout.orderId
            )}
          </Typography>
          <PageFooterCrumb elevation={10} />
        </Box>
      </Container>
    </>
  );
}
