import { Box, Button, Card, Typography } from "@mui/material";
import { useState } from "react";
import IColorType from "src/theme/new-ui/color.schema";
import {
  ButtonPrimaryColor,
  ButtonPrimaryDisabledColor,
  ButtonPrimaryTextColor,
} from "src/theme/new-ui/component.color";

interface Props {
  isFilled?: boolean;
  disabled?: boolean;
  colorType?: IColorType;
  dialDimen: number;
  shadowHeight: number;
  fontSize: number;
  number: string;
  onClick: (number: string) => void;
}

const ANIM_DURATION_MS = 90;

export default function PressableDial({
  isFilled = false,
  disabled = false,
  colorType = disabled
    ? ButtonPrimaryDisabledColor
    : isFilled
    ? ButtonPrimaryColor
    : ButtonPrimaryTextColor,
  dialDimen,
  shadowHeight,
  fontSize,
  number,
  onClick,
}: Props) {
  const [marginTop, setMarginTop] = useState(shadowHeight);

  const handlePress = () => {
    setMarginTop(0);
    setTimeout(() => {
      setMarginTop(shadowHeight);
      onClick(number);
    }, ANIM_DURATION_MS);
  };

  return (
    <div>
      <Box
        sx={{
          width: `${dialDimen}px`,
          height: `${dialDimen}px`,
          borderRadius: "50%",
          backgroundColor: "#C4CDD5",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: `${dialDimen}px`,
            height: `${dialDimen}px`,
            borderRadius: "50%",
            backgroundColor: "#DFE3E8",
            position: "absolute",
            zIndex: 2,
            marginTop: `-${marginTop}px`,
            transition: `margin-top ${ANIM_DURATION_MS}ms linear 0s`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            handlePress();
          }}
        >
          <Typography
            fontSize={`${fontSize}px`}
            fontWeight={700}
            color={"#454F5B"}
            zIndex={3}
          >
            {number}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
