import {
  LinearProgress,
  Stack,
  Typography,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { MAX_PAYMENT_TIMEOUT } from "src/config";
import { usePaymentTimerContext } from "src/context/PaymentTimerContext/usePaymentTimerContext";
import { getCurrentLanguage } from "src/i18n";
import { useSelector } from "src/redux/store";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#394AE2",
      lighter: "#394AE2",
      darker: "#394AE2",
      contrastText: "#fff",
    },
  },
});

const Countdown: React.FC<{
  /*  seconds: number;
  timeLeft?: number;
  setTimeLeft: (timeLeft: number) => void; */
  onTimeout?: (() => void) | null;
  fontSize?: number;
}> = ({
  /* seconds,
  timeLeft = 300,
  setTimeLeft, */
  onTimeout = null,
  fontSize = 12,
}) => {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  // const [timeLeft, setTimeLeft] = useState(seconds);

  const { timeLeft, MAX_PAYMENT_TIMEOUT } = usePaymentTimerContext();
  // console.log("timeLeft", timeLeft);

  const [maxTime, setMaxTime] = useState(MAX_PAYMENT_TIMEOUT);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      if (onTimeout != null) onTimeout();
      return;
    }

    if(timeLeft <= 10){
      setMaxTime(10);
    }
  }, [timeLeft]);


  // display the countdown timer
  return (
    <div style={{ minHeight: "50px", width: "100%" }}>
      {timeLeft <= 10 && (
        <Stack
          marginTop={1}
          marginBottom={1}
          spacing={0.5}
          display={"flex"}
          flex={1}
          width={"100%"}
          direction={"column"}
        >
          <Typography
            alignSelf={"center"}
            marginTop={"8px"}
            fontSize={fontSize}
            fontWeight={600}
            color={"#394AE2"}
          >
            {displayTexts?.payment.duration.replace(
              "{{duration}}",
              `${timeLeft}`
            )}
          </Typography>
          <ThemeProvider theme={customTheme}>
            <LinearProgress
              variant="determinate"
              value={(timeLeft / maxTime) * 100}
              sx={{
                color: "#394AE2",
                backgroundColor: `#DFE3E8`,
              }}
            />
          </ThemeProvider>
        </Stack>
      )}
    </div>
  );
};

export default Countdown;
