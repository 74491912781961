import { io, Socket } from "socket.io-client";
import SocketObserver, { SocketEvents } from "src/observers/socketObserver";

interface SocketMessage {
  data: any;
  error: boolean;
  message: string;
}
export class SocketClient {
  private static instance: SocketClient;
  private socket: Socket;
  socketObserverInstance: SocketObserver;

  constructor(private serverUrl: string) {
    this.socket = io(this.serverUrl);
    this.registerEventHandlers();
    this.socketObserverInstance = SocketObserver.getInstance();
  }

  public static init(serverUrl: string) {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient(serverUrl);
    }
  }

  public static getInstance(): SocketClient {
    return SocketClient.instance;
  }

  private registerEventHandlers() {
    this.socket.on("connect", () => {
      console.log("Connected to the socket server");
      this.socketObserverInstance.notify(SocketEvents.SOCKET_STATUS, {
        socket: this.socket,
        status: true,
      });
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from the socket server");
      this.socketObserverInstance.notify(SocketEvents.SOCKET_STATUS, {
        socket: null,
        status: false,
      });
    });

    this.socket.on("ORDER_INIT_RESPONSE", (data: SocketMessage) => {
      console.log("Received message:", data);
      this.socketObserverInstance.notify(
        SocketEvents.ORDER_INIT_RESPONSE,
        data
      );
    });

    this.socket.on("ORDER_ITEM_RESPONSE", (data: SocketMessage) => {
      console.log("Received message:", data);
      this.socketObserverInstance.notify(
        SocketEvents.ORDER_ITEM_RESPONSE,
        data
      );
    });

    this.socket.on("ORDER_STATUS", (data: SocketMessage) => {
      console.log("Received message:", data);
      this.socketObserverInstance.notify(SocketEvents.ORDER_STATUS, data);
    });

    this.socket.on("error", (error: any) => {
      console.error("Socket error:", error);
      this.socketObserverInstance.notify(SocketEvents.SOCKET_ERROR, {
        error,
      });
    });

    this.socket.on("BROADCAST", (data: SocketMessage) => {
      console.log("Received message:", data);
      this.socketObserverInstance.notify(SocketEvents.BROADCAST, data);
    });
  }

  sendMessage(topic: string, message: any) {
    console.log("Sending message:", message, "to topic:", topic);
    this.socket.emit(topic, message);
  }
}
