import { Box, Stack } from "@mui/material";
import { useState } from "react";
import IColorType from "src/theme/new-ui/color.schema";
import {
  TabSelectedColor,
  TabUnselectedColor,
} from "src/theme/new-ui/component.color";
import PressableTabItem from "./PressableTabItem";

interface Props {
  selectedColorType?: IColorType;
  unselectedColorType?: IColorType;
  tabs: { tabTitle: string | undefined }[];
  borderRadius?: number;
  marginTop?: number;
  tabHeight?: number;
  shadowHeight?: number;
  fontSize?: number;
  current: number;
  onChange: (position: number) => void;
}

export default function PressableTabGroup({
  selectedColorType = TabSelectedColor,
  unselectedColorType = TabUnselectedColor,
  tabs,
  borderRadius = 16,
  marginTop = 40,
  tabHeight = 40,
  shadowHeight = 6,
  fontSize = 12,
  current,
  onChange,
}: Props) {
  const [currentSelection, setCurrentSelection] = useState(current);

  const handleOnClick = (index: number) => {
    setCurrentSelection(index);
    onChange(index);
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      sx={{ marginTop: `${marginTop}px` }}
      onClick={() => handleOnClick}
    >
      {tabs.map((tab, index) => {
        return (
          <PressableTabItem
            index={index}
            isStart={index == 0}
            isEnd={index == tabs.length - 1}
            borderRadius={borderRadius}
            tab={tab}
            tabHeight={tabHeight}
            shadowHeight={shadowHeight}
            fontSize={fontSize}
            colorType={
              currentSelection == index
                ? selectedColorType
                : unselectedColorType
            }
            onClick={handleOnClick}
          />
        );
      })}
    </Stack>
  );
}
