import { Subject } from "rxjs";
import { v4 as uuidv4 } from "uuid";


export enum SocketEvents {
    SOCKET_STATUS = "SOCKET_STATUS",
    SOCKET_ERROR = "SOCKET_ERROR",
    ORDER_INIT_RESPONSE = "ORDER_INIT_RESPONSE",
    ORDER_STATUS = "ORDER_STATUS",
    ORDER_ITEM_RESPONSE = "ORDER_ITEM_RESPONSE",
    BROADCAST = "BROADCAST",
}

const InitialValue: Record<string, Subject<any>> = {};


export default class SocketObserver {
    private static instance: SocketObserver;

    subjects: Record<string, Subject<any>>;
    subscriberList: Record<string, ReturnType<Subject<any>["subscribe"]>>;

    private constructor() {
        console.log("SocketObserver Instantiated :: Using RxJS");
        for (const channelOrEvent of Object.keys(SocketEvents)) {
            InitialValue[channelOrEvent] = new Subject<any>();
        }
        this.subjects = InitialValue;
        this.subscriberList = {};
    }

    public static getInstance() {
        if (!SocketObserver.instance) {
            SocketObserver.instance = new SocketObserver();
        }
        return SocketObserver.instance;
    }

    subscribe(
        channelOrEvent: SocketEvents,
        func: (value: any) => void
    ): string {
        const subscriptionId: string = uuidv4();
        const subscription: ReturnType<Subject<any>["subscribe"]> = this.subjects[
            channelOrEvent
        ].subscribe({
            next: func,
        });

        this.subscriberList[subscriptionId] = subscription;

        return subscriptionId;
    }

    subscribeMultiple(
        channelsOrEvents: SocketEvents[],
        func: (value: any) => void
    ): Array<string> {
        const subscriptionIdList: Array<string> = [];
        channelsOrEvents.forEach((channelOrEvent: SocketEvents) => {
            const subscriptionId: string = uuidv4();
            const subscription: ReturnType<Subject<any>["subscribe"]> = this.subjects[
                channelOrEvent
            ].subscribe({
                next: func,
            });
            this.subscriberList[subscriptionId] = subscription;
            subscriptionIdList.push(subscriptionId);
        });
        return subscriptionIdList;
    }

    unsubscribe(subscriptionId: string) {
        if (!subscriptionId) return;
        console.log(`SocketObserver :: unsubscribe :: ${subscriptionId} `);
        this.subscriberList[subscriptionId].unsubscribe();
    }

    unsubscribeMultiple(subscriptionIdList: Array<string>) {
        console.log(
            `SocketObserver :: unsubscribeMultiple :: ${subscriptionIdList.join(", ")} `
        );
        subscriptionIdList.forEach((subscriptionId: string) => {
            this.subscriberList[subscriptionId].unsubscribe();
        });
    }

    notify(channelOrEvent: SocketEvents, data: any) {
        console.log(`SocketObserver :: notify :: ${channelOrEvent} `, data);
        this.subjects[channelOrEvent].next(data);
    }

    end() {
        for (const channelOrEvent of Object.keys(SocketEvents)) {
            this.subjects[channelOrEvent].complete();
        }
    }
}
