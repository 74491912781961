import { createContext, useEffect, useState } from "react";
import { PaymentTimerContextType } from "./paymentTypes";
import { MAX_PAYMENT_TIMEOUT as _MAX_PAYMENT_TIMEOUT } from "src/config";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";

export const PaymentTimerContext =
  createContext<PaymentTimerContextType | null>(null);

type PaymentTimerProviderProps = {
  children: React.ReactNode;
};

export function PaymentTimerProvider({ children }: PaymentTimerProviderProps) {
  const { machineConfiguration } = useMachineConfigurationContext();
  const [timeLeft, setTimeLeft] = useState(
    machineConfiguration.kiosk_payment_timeout
      ? machineConfiguration.kiosk_payment_timeout
      : _MAX_PAYMENT_TIMEOUT
  );

  const [MAX_PAYMENT_TIMEOUT] = useState(
    machineConfiguration.kiosk_payment_timeout
      ? machineConfiguration.kiosk_payment_timeout
      : _MAX_PAYMENT_TIMEOUT
  );

  useEffect(() => {
    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    if (timeLeft == 0) clearInterval(intervalId);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <PaymentTimerContext.Provider value={{ timeLeft, MAX_PAYMENT_TIMEOUT }}>
      {children}
    </PaymentTimerContext.Provider>
  );
}
