import React, { FunctionComponent, useState, MutableRefObject } from "react";
import Keyboard, { KeyboardInput } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

interface IProps {
  onChangeAll: (input: KeyboardInput) => void;
  keyboardRef: MutableRefObject<any>;
  inputName: string;
  maxLength?: number;
}

const KeyboardWrapper: FunctionComponent<IProps> = ({
  onChangeAll,
  keyboardRef,
  inputName,
  maxLength,
}) => {
  const [layoutName, setLayoutName] = useState("default");

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
  };

  return (
    <Keyboard
      /*  debug={true} */
      keyboardRef={(r) => (keyboardRef.current = r)}
      layoutName={layoutName}
      inputName={inputName}
      //onChange={onChange}
      disableCaretPositioning={false}
      onChangeAll={onChangeAll}
      onKeyPress={onKeyPress}
      /* onRender={() => console.log("Rendered")} */
      theme={
        "hg-theme-default hg-theme-numeric hg-layout-numeric numeric-theme"
      }
      layout={{
        default: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
        /* shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"] */
      }}
      /* mergeDisplay */
      display={{
        "{clear}": "Clear",
        "{bksp}": "&#8592",
      }}
      maxLength={maxLength || 50}
    />
  );
};

export default KeyboardWrapper;
