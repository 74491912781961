import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { getCurrentLanguage } from "src/i18n";
import { useSelector } from "src/redux/store";
import { openServiceMode } from "src/services/machineConfiguration.service";

interface Props {
  elevation: number;
}

function PageFooterCrumb({ elevation }: Props) {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const [serviceModeState, setServiceModeState] = useState({
    isError: false,
    errorMsg: "",
  });

  const [reload, setReload] = useState(0);

  const handleOpenningOfServiceMode = async () => {
    if (reload >= 4 && navigator.onLine) {
      const res = await openServiceMode();

      setServiceModeState({
        isError: res.isError,
        errorMsg: res.errorMsg,
      });
    }
    return setReload(reload + 1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color="inherit"
        elevation={elevation}
        position="fixed"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar
          variant="dense"
          sx={{
            minHeight: "32px",
            height: "48px",
            justifyContent: "center",
          }}
        >
          <Typography fontSize={"20px"} marginRight={"10px"}>
            {displayTexts?.app.poweredBy}
          </Typography>
          <img
            width={16}
            src="/assets/configAssets/AppHeaderLogo.svg"
            onClick={handleOpenningOfServiceMode}
          />
          <Typography fontSize={"20px"} marginLeft={"10px"}>
            {displayTexts?.app.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default PageFooterCrumb;
