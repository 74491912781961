import { useContext } from "react";
import { PaymentTimerContext } from "./PaymentTimer.context";
//

// ----------------------------------------------------------------------

export const usePaymentTimerContext = () => {
  const context = useContext(PaymentTimerContext);

  if (!context)
    throw new Error(
      "usePaymentTimerContext context must be use inside PaymentTimerProvider"
    );

  return context;
};
