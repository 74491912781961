import { Navigate, useRoutes } from "react-router-dom";
// redux
import { Provider as ReduxProvider } from "react-redux";
import { store } from "../redux/store";

// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config";
import { MachineConfigurationProvider } from "src/machineConfig/machineConfig.context";
import { NotificationProvider } from "../context/notification/NotificationContext";
import { SocketProvider } from "../context/socketContext/SocketContext";

import LoginPage from "src/pages/auth/LoginPage";
import ShopVariant from "src/variants/ShopVariant";
import PaymentVariant from "src/variants/PaymentVariant";
import VendingVariant from "src/variants/VendingVariant";
import FeedbackVariant from "src/variants/FeedbackVariant";
import VendingPage from "src/pages/dashboard/vending/VendingPageParent";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <MachineConfigurationProvider>
            <SocketProvider>
              <ReduxProvider store={store}>
                <NotificationProvider>
                  <DashboardLayout />
                </NotificationProvider>
              </ReduxProvider>
            </SocketProvider>
          </MachineConfigurationProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/shop" replace />,
              index: true,
            },
            { path: "shop", element: <ShopVariant /> },
            { path: "checkout", element: <PaymentVariant /> },
            { path: "vend", element: <VendingPage /> },
            { path: "feedback", element: <FeedbackVariant /> },
          ],
        },
      ],
    },
    { path: "*", element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
  ]);
}
