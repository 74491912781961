// @mui
import { Typography, Stack, DialogProps } from "@mui/material";
// components
import { DialogAnimate } from "../../../components/animate";
// assets

import InternalServerErrorImage from "src/assets/ErrorDialog/internal-server-error.webp";
import Image from "src/components/image/Image";
import { PaymentScreenLoader } from "src/components/paymentScreenLoader";
import { useEffect } from "react";

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  cancelOrder: () => void;
}

export default function ErrorDialog({ open, onReset, cancelOrder }: Props) {
  useEffect(() => {
    setTimeout(() => {
      cancelOrder();
    }, 5000);
  }, []);

  return (
    <DialogAnimate
      /* fullScreen */
      open={open}
      PaperProps={{
        sx: {
          maxWidth: { md: "calc(100% - 48px)" },
          maxHeight: { md: "calc(100% - 100px)" },
          paddingTop: 5,
          paddingBottom: 5,
        },
      }}
    >
      <Stack
        spacing={2}
        sx={{
          m: "auto",
          maxWidth: 580,
          textAlign: "center",
          px: { xs: 2, sm: 0 },
        }}
      >
        <Typography variant="h4">Something went wrong!</Typography>

        <Image
          key={InternalServerErrorImage}
          alt="logo card"
          src={InternalServerErrorImage}
          sx={{
            /* width: {
                  xs: "60px",
                  sm: "100px",
                }, */
            /* height: {
                  xs: "50px",
                  sm: "100px",
                }, */
            height: 260,
          }}
        />

        <Typography variant="subtitle2">Please Try Again.</Typography>

        <PaymentScreenLoader />
      </Stack>
    </DialogAnimate>
  );
}
