import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
// form
// @mui
import { Container, Typography, Box, Stack } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import {
  setProducts,
  initOrderCycle,
  createPaymentMethod,
} from "../../../redux/slices/product";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// @types
// components
import { useSettingsContext } from "../../../components/settings";
// sections
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { OrderService } from "src/services";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant2/PageHeaderCrumb";
import PageFooterAction from "../../../sections/@dashboard/crumbs/variant2/PageFooterActions";
import { getCurrentLanguage } from "src/i18n";
import ShopProductListVariant2 from "src/sections/@dashboard/e-commerce/shop/ShopProductListVariant2";

export default function EcommerceShopPageVariant2() {
  console.log("EcommerceShopPageVariant2");

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { products: _products } = useMachineConfigurationContext();
  const { products, checkout } = useSelector((state) => state.product);
  const [openFilter, setOpenFilter] = useState(false);

  const { cart } = useSelector((state) => state.product.checkout);

  useEffect(() => {
    dispatch(setProducts(_products));
  }, [dispatch]);

  const handelConfirmCheckout = async () => {
    try {
      dispatch(
        createPaymentMethod({ payment: { value: PAYMENT_METHODS.PAYTM } })
      );
      const data = cart.map((product) => ({
        pos: product.pos,
        quantity: product.quantity,
      }));
      const response = await OrderService.generateRequestId(data);
      console.log(response);

      if (response.error) return; // TODO: Handle error

      dispatch(initOrderCycle({ request_id: response.request_id }));
      navigate(PATH_DASHBOARD.eCommerce.checkout, {
        replace: true,
      });
    } catch (error) {
      // TODO: Handle Error
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{displayTexts?.products.helmetTitle}</title>
      </Helmet>
      <>
        <Container maxWidth={false} disableGutters={true}>
          <PageHeaderCrumb
            elevation={10}
            handleHelpPageClick={() => {
              console.log("handleHelpPageClick");
            }}
          />
          {/* <Box sx={{ marginTop: "80px" }}>
            <Carousel>
              <Stack direction={"column"}>
                <img src={AppHeaderBanner} width={"1600px"} />
                <Typography></Typography>
              </Stack>
            </Carousel>
          </Box> */}
          <Box sx={{ marginTop: "90px", marginBottom: "90px" }}>
            <ShopProductListVariant2
              products={products}
              loading={!products.length}
            />
          </Box>
          <Box>
            <PageFooterAction
              positiveAction={handelConfirmCheckout}
              negativeAction={() => {}}
            />
          </Box>
        </Container>
      </>
    </>
  );
}
