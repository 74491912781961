import { useContext } from "react";

import { MachineConfigurationContext } from "./machineConfig.context";

export const useMachineConfigurationContext = () => {
  const context = useContext(MachineConfigurationContext);

  if (!context)
    throw new Error(
      "useMachineConfigurationContext context must be use inside MachineConfigurationProvider"
    );

  return context;
};
