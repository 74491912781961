import axios from "../utils/axios";
import { axiosInstanceServiceMode } from "../utils/axios";

async function machineLogin(machineId: string, password: string): Promise<any> {
  try {
    const response = await axios.post("/machineLogin", {
      machineId,
      password,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchTokenFromServiceMode(): Promise<any> {
  try {
    const response = await axiosInstanceServiceMode.get("/getToken");
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export { machineLogin, fetchTokenFromServiceMode };
