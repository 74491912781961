import axios from "axios";
// config
import { BASE_URL, BASE_URL_LANG, BASE_URL_V2, SERVICE_MODE_URL } from "../config";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: BASE_URL });

const axiosInstanceV2 = axios.create({
  baseURL: BASE_URL_V2,
});

const axiosInstanceServiceMode = axios.create({
  baseURL: SERVICE_MODE_URL,
});

const axiosInstanceLang = axios.create({
  baseURL: BASE_URL_LANG,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

axiosInstanceV2.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

axiosInstanceLang.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
export { axiosInstanceV2, axiosInstanceLang, axiosInstanceServiceMode };
