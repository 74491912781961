import { Box, Stack } from "@mui/material";
import { useState } from "react";
import IColorType from "src/theme/new-ui/color.schema";
import {
  CardPrimarySelectedColor,
  CardPrimaryUnSelectedColor,
} from "src/theme/new-ui/component.color";
import PressableOptionItem, { OptionItem } from "./PressableOptionItem";

interface Props {
  cardWidth?: number;
  cardHeight?: number;
  shadowHeight?: number;
  borderRadius?: number;
  maxImageWidth?: number;
  marginTop?: number;
  fontSize?: number;
  direction?: "column" | "row";
  spacing?: number;
  selectedColorType?: IColorType;
  unselectedColorType?: IColorType;
  options: Array<OptionItem>;
  selected?: OptionItem | null;
  onSelected: (option: OptionItem) => void;
}

const PressableOptionGroup = ({
  cardWidth = 80,
  cardHeight = 80,
  shadowHeight = 4,
  maxImageWidth = 50,
  borderRadius = 16,
  fontSize = 12,
  marginTop = 2,
  spacing = 1,
  selectedColorType = CardPrimarySelectedColor,
  unselectedColorType = CardPrimaryUnSelectedColor,
  direction = "column",
  options,
  selected = options.length > 0 ? options[0] : null,
  onSelected,
}: Props) => {
  const [selection, setSelection] = useState(selected);

  const handleOptionChange = (option: OptionItem) => {
    setSelection(option);
    onSelected(option);
  };

  return (
    <Stack direction={direction} spacing={spacing} sx={{}}>
      {options.map((option, index) => {
        return (
          <Box key={index}>
            <PressableOptionItem
              maxImageWidth={maxImageWidth}
              imgTextGap={marginTop}
              fontSize={fontSize}
              borderRadius={borderRadius}
              key={index}
              cardWidth={cardWidth}
              cardHeight={cardHeight}
              shadowHeight={shadowHeight}
              option={option}
              onSelected={handleOptionChange}
              selected={selected?.id == option.id}
              colorType={
                selected?.id == option.id
                  ? selectedColorType
                  : unselectedColorType
              }
            />
          </Box>
        );
      })}
    </Stack>
  );
};

export default PressableOptionGroup;
