import "./index.css";

// i18n
import "./locales/i18n";

// scroll bar
import "simplebar/src/simplebar.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// ----------------------------------------------------------------------

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// components
import { SettingsProvider } from "./components/settings";
import ScrollToTop from "./components/scroll-to-top";

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from "./auth/JwtContext";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Scrollbar from "./components/scrollbar/Scrollbar";

// ----------------------------------------------------------------------

/* Sentry.init({
  dsn: "https://<key>@sentry.io/<project>"
}); */

if ("serviceWorker" in navigator) {
  window.navigator.serviceWorker
    .register("/sw.js")
    .then((reg) => {
      // eslint-disable-next-line no-param-reassign
      reg.onupdatefound = () => {
        const newSW = reg.installing;
        newSW?.postMessage("skipWaiting");
      };
    })
    .catch((e) => {
      console.log("error in registering ", e);
      // // console.log(e);
    });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthProvider>
    <HelmetProvider>
      <SettingsProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Scrollbar /* sx={{ p: 0, pb: 0 }} */>
            <App />
          </Scrollbar>
        </BrowserRouter>
      </SettingsProvider>
    </HelmetProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
