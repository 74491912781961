import axios, { axiosInstanceV2 } from "../utils/axios";

// * 1.1
async function generateRequestId(
  items: {
    quantity: number;
    pos: string;
  }[]
): Promise<any> {
  try {
    const response = await axios({
      method: "post",
      url: "/api/generateRequestId",
      data: {
        items,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 1.2 V2
async function checkoutRequestV2(data: {
  payment_method: string;
  request_id: string;
  phone?: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/checkoutRequestV2",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 1.3
async function checkStatus(data: {
  payment_method: string;
  request_id: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/checkStatus",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 1.3
async function cacelOrder(data: { request_id: string }): Promise<any> {
  try {
    const response = await axios({
      method: "post",
      url: "/api/updateRequestStatus",
      data: {
        ...data,
        status: "cancelled",
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function orderFeedback(data: {
  bill_id: string;
  value: number;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/feedback",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function authoriseSwiftPayment(data: {
  request_id: string;
  phone_number: string;
  otp: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/authoriseSwiftPayment",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function initResponseCashModule(data: {
  requestId: string;
  status: string;
  comment: string;
  code: number;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/initResponseCashModule",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function cashTxnUpdateV2(data: {
  amount_added: number;
  requestId: string;
  comment: string;
  note_uuid: string;
  status: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/cashTxnUpdateV2",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function verifyRFIDdata(data: {
  requestId: string;
  rfidData: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/rfidData",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}
// api/disableCashModule
// /api/initResponseCashModule
// api/cashTxnUpdateV2

export {
  generateRequestId,
  checkoutRequestV2,
  checkStatus,
  cacelOrder,
  orderFeedback,
  authoriseSwiftPayment,
  initResponseCashModule,
  cashTxnUpdateV2,
  verifyRFIDdata
};
