import { VARIANT } from "src/config";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import EcommerceVendingPageVariant1 from "src/pages/dashboard/vending/EcommerceVendingPageVariant1";
import EcommerceVendingPageVariant2 from "src/pages/dashboard/vending/EcommerceVendingPageVariant2";

function VendingVariant() {
  const { machineData } = useMachineConfigurationContext();

  //switch (VARIANT) {
  switch (machineData.machine_type) {
    case "big":
      return <EcommerceVendingPageVariant1 />;
    default:
      return <EcommerceVendingPageVariant2 />;
  }
}

export default VendingVariant;
