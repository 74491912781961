import { Stack, Typography } from "@mui/material";
import LoginLayout from "../../layouts/login";
import AuthLoginForm from "./AuthLoginForm";
import Lang from "src/i18n/en";
import { fetchTokenFromServiceMode } from "src/services/auth.service";
import { useEffect } from "react";

export default function Login() {
  const fetchToken = async () => {
    try {
      const data = await fetchTokenFromServiceMode();
      localStorage.setItem("accessToken", data.token);
      console.log(data);
      document.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ position: "relative" }}>
        <Typography variant="h4">{Lang.login.setUp}</Typography>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
