import { Button, Card, Stack, Typography } from "@mui/material";
import { useState } from "react";
import IColorType from "src/theme/new-ui/color.schema";
import {
  ButtonPrimaryColor,
  ButtonPrimaryDisabledColor,
  ButtonPrimaryTextColor,
} from "src/theme/new-ui/component.color";

interface Props {
  fullWidth?: boolean;
  isFilled?: boolean;
  disabled?: boolean;
  colorType?: IColorType;
  buttonHeight?: number;
  buttonWidth?: number;
  shadowHeight?: number;
  borderRadius?: number;
  fontSize?: number;
  text: string | undefined;
  startIcon?: any;
  endIcon?: any;
  onClick: () => void;
}

const ANIM_DURATION_MS = 60;

export default function PressableButton({
  fullWidth = false,
  isFilled = false,
  disabled = false,
  colorType = disabled
    ? ButtonPrimaryDisabledColor
    : isFilled
    ? ButtonPrimaryColor
    : ButtonPrimaryTextColor,
  buttonHeight = 32,
  buttonWidth,
  shadowHeight = 4,
  borderRadius = 16,
  fontSize = 12,
  text,
  startIcon = null,
  endIcon = null,
  onClick,
}: Props) {
  const [marginTop, setMarginTop] = useState(0);
  const [height, setHeight] = useState(buttonHeight + shadowHeight);

  const handlePress = () => {
    setMarginTop(shadowHeight);
    setHeight(buttonHeight);
    setTimeout(() => {
      setMarginTop(0);
      setHeight(buttonHeight + shadowHeight);
      onClick();
    }, ANIM_DURATION_MS);
  };

  let customWidth: any = {};
  if (!fullWidth && buttonWidth) customWidth.width = `${buttonWidth}px`;

  return (
    <div>
      <Card
        sx={[
          {
            boxShadow: 1,
            marginTop: `${marginTop}px`,
            height: `${height}px`,
            transition: `margin-top ${ANIM_DURATION_MS}ms linear 0s, height ${ANIM_DURATION_MS}ms linear 0s`,
            ...customWidth,
            left: "0px",
            top: "0px",
            borderRadius: `${borderRadius}px`,
            backgroundColor: `${colorType.shadow}`,
          },
        ]}
      >
        <Card
          sx={[
            {
              boxShadow: 0,
              height: `${buttonHeight}px`,
              ...customWidth,
              left: "0px",
              top: "0px",
              borderRadius: `${borderRadius}px`,
              backgroundColor: `${colorType.main}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <Button
            fullWidth={fullWidth}
            disabled={disabled}
            variant="text"
            sx={{
              width: "100%",
              textTransform: "none",
            }}
            onClick={() => {
              handlePress();
            }}
          >
            {startIcon != null && startIcon}
            <Typography
              color={`${colorType.text}`}
              fontSize={`${fontSize}px`}
              fontWeight={"700"}
              align="center"
            >
              {text}
            </Typography>
            {endIcon != null && endIcon}
          </Button>
        </Card>
      </Card>
    </div>
  );
}
