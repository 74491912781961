import IColorType from "./color.schema";
import generateAlpha from "./color.utils";

export const COLOR_PRIMARY = "#D80000";
export const COLOR_PRIMARY_STROKE = "#EC7E7E";
export const COLOR_SECONDARY = "#36B37E";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_GRAY = "#919EAB";
export const COLOR_TRANSPARENT = "#FFFFFF00";
export const COLOR_TEXT_ON_WHITE = "#454F5B";
export const COLOR_TEXT_ON_WHITE_LIGHT = "#637381";
export const COLOR_GRAY_SHADOW = "#C4CDD5";

export const CardPrimaryColor: IColorType = {
  main: COLOR_WHITE,
  shadow: generateAlpha(COLOR_PRIMARY, 0.3),
};

export const CardPrimarySelectedColor: IColorType = {
  main: COLOR_WHITE,
  shadow: generateAlpha(COLOR_PRIMARY, 0.3),
  stroke: COLOR_PRIMARY_STROKE,
};

export const CardPrimaryUnSelectedColor: IColorType = {
  main: COLOR_WHITE,
  shadow: generateAlpha(COLOR_GRAY, 0.3),
  stroke: COLOR_WHITE,
};

export const ButtonPrimaryColor: IColorType = {
  main: COLOR_PRIMARY,
  shadow: generateAlpha(COLOR_PRIMARY, 0.3),
  text: COLOR_WHITE,
};

export const ButtonSecondaryColor: IColorType = {
  main: COLOR_SECONDARY,
  shadow: generateAlpha(COLOR_SECONDARY, 0.3),
  text: COLOR_WHITE,
};

export const ButtonPrimaryTextColor: IColorType = {
  main: COLOR_WHITE,
  shadow: generateAlpha(COLOR_PRIMARY, 0.3),
  text: COLOR_PRIMARY,
};

export const ButtonGrayTextColor: IColorType = {
  main: COLOR_WHITE,
  shadow: generateAlpha(COLOR_TEXT_ON_WHITE, 0.3),
  text: COLOR_TEXT_ON_WHITE,
};

export const ButtonPrimaryDisabledColor: IColorType = {
  main: COLOR_GRAY,
  shadow: generateAlpha(COLOR_GRAY, 0.3),
  text: COLOR_WHITE,
};

export const TabSelectedColor: IColorType = {
  main: COLOR_PRIMARY,
  shadow: generateAlpha(COLOR_PRIMARY, 0.3),
  text: COLOR_WHITE,
};

export const TabUnselectedColor: IColorType = {
  main: COLOR_WHITE,
  shadow: generateAlpha(COLOR_GRAY, 0.3),
  text: COLOR_TEXT_ON_WHITE,
};

export const SnackbarColor: IColorType = {
  main: COLOR_WHITE,
  shadow: COLOR_GRAY_SHADOW,
  text: COLOR_PRIMARY,
};

export const DialogColor: IColorType = {
  main: COLOR_WHITE,
  shadow: COLOR_GRAY_SHADOW,
  text: COLOR_PRIMARY,
};
