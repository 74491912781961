import { createSlice } from "@reduxjs/toolkit";
import { ISettingsState } from "src/@types/settings";
import LangConsts from "src/i18n";

const initialState: ISettingsState = {
  language: {
    language: LangConsts.ENGLISH,
  },
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export default slice.reducer;

export const { changeLanguage } = slice.actions;
