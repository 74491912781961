import axios, { axiosInstanceLang } from "../utils/axios";

// * 0.2
async function machineValidate(): Promise<any> {
  console.log("CALLING MACHINE VALIDATE");

  try {
    const response = await axios.post("/api/machineValidate");
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 0.3
async function checkVMC(): Promise<any> {
  try {
    const response = await axios.get("/api/checkVMC");
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 0.4
async function fetchMachineItems(): Promise<any> {
  try {
    const response = await axios.get("/api/machineItems");
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 0.5
async function machineStatus(): Promise<any> {
  try {
    const response = await axios.post("/api/machineStatus");
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getLanguageData(): Promise<any> {
  try {
    const response = await axiosInstanceLang.get("api/internationalisation");
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function checkIfServiceModeIsOn(): Promise<any> {
  return { error: false };
  // try {
  //   const something = await axios.get(`http://192.168.1.7:4000`);

  //   // console.log('something', something);
  //   return { error: false };
  // } catch (err) {
  //   return {
  //     error: true,
  //   };
  // }
}

async function openServiceMode(): Promise<any> {
  try {
    const { error } = await checkIfServiceModeIsOn();
    if (!error) {
      window.location.href =
        process.env.REACT_APP_SERVICE_MODE_FRONTEND_URL || "";
      return Promise.resolve({
        isError: false,
        errorMsg: "Service mode app is not runnning",
      });
    } else {
      return Promise.resolve({
        isError: false,
        errorMsg: "Service mode app is runnning",
      });
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  machineValidate,
  checkVMC,
  fetchMachineItems,
  machineStatus,
  getLanguageData,
  openServiceMode,
};
