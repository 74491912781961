import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SocketObserver, { SocketEvents } from "src/observers/socketObserver";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import { OrderService } from "src/services";
import { SocketContext } from "src/context/socketContext/SocketContext";
import { verifyRFIDdata } from "src/services/order.service";
import { Stack, Typography } from "@mui/material";
import PressableButton from "src/components/pressables/PressableButton";
import { getCurrentLanguage } from "src/i18n";
import {
  ButtonGrayTextColor,
  COLOR_GRAY,
} from "src/theme/new-ui/component.color";

interface Props {
  cancelOrder: VoidFunction;
  proceedToNextStep: VoidFunction;
}

function RFIDPaymentMethodVariant1({ cancelOrder, proceedToNextStep }: Props) {
  const { checkout } = useSelector((state: any) => state.product);
  const socketObserverInstance = SocketObserver.getInstance();
  const socket = React.useContext(SocketContext);
  const subscriptionIdList = useRef<Array<string>>([]);
  const [rfidStatus, setRfidStatus] = useState("INITIATING");

  const displayTexts = getCurrentLanguage(
    useSelector((state: any) => state.seetings.language).language
  );

  const handleEnablingRFID = async (data: any) => {
    if (data.topic === "RFID_ENABLED" && data.status === true) {
      setRfidStatus("ENABLED");
    }

    if (data.topic === "RFID_DISABLED" && data.status === false) {
      setRfidStatus("DISABLED");
    }

    if (data.topic === "RFID_SEND_TAG" && data.status === true) {
      await verifyRFIDdata({
        requestId: data.requestId,
        rfidData: data.rfidData,
      });

      setTimeout(() => {
        proceedToNextStep();
      }, 0);

      return;
    }
  };

  const data = useRef({
    request_id: checkout.orderId,
    payment_method: PAYMENT_METHODS.RFID,
  });

  const resetEverything = () => {
    // setIsPhoneNoAdded(false);
    // setPhoneNumber("");
    // setOtp("");
    // setDialed("");
    // setCode(""); // do not reset phone code for now
    // setIsLoading(false);
    // setIsError(false);
  };

  const callCheckoutApi = async () => {
    try {
      const response = await OrderService.checkoutRequestV2(data.current);
      if (!response.error) {
        socket?.sendMessage(
          "BROADCAST",
          JSON.stringify({ topic: "RFID_ENABLED", ...response })
        );
        // setIsTryAgain(false);
      } else {
        // setIsTryAgain(true);
        return;
      }
    } catch (error) {
      // TODO: Handle Error
      console.error(error);
      //   openErrorDialog();
    }
  };

  useEffect(() => {
    callCheckoutApi();
  }, []);

  useEffect(() => {
    subscriptionIdList.current.push(
      socketObserverInstance.subscribe(
        SocketEvents.BROADCAST,
        handleEnablingRFID
      )
    );
    return () => {
      socket?.sendMessage(
        "BROADCAST",
        JSON.stringify({ topic: "RFID_DISABLED" })
      );
      subscriptionIdList.current.forEach((id) =>
        socketObserverInstance.unsubscribe(id)
      );
    };
  }, []);

  return (
    <div>
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {rfidStatus === "INITIATING" && (
          <>
            <div style={{ textAlign: "center" }}>Please Wait</div>
            <div>We are checking card acceptor</div>
          </>
        )}
        {rfidStatus === "ENABLED" && (
          <>
            <div style={{ textAlign: "center" }}>Please Tap Card</div>
          </>
        )}
        {rfidStatus === "DISABLED" && (
          <>
            <div style={{ textAlign: "center" }}>
              Card Acceptor is not working
            </div>
            <button onClick={() => setRfidStatus("INITIATING")}>
              Try Again
            </button>
          </>
        )}

        <Stack
          sx={{
            position: "absolute",
            bottom: 20,
          }}
          direction={"column"}
          alignItems={"center"}
          spacing={0}
        >
          <Stack direction={"row"} spacing={1}>
            <PressableButton
              text={displayTexts?.payment.cancelPayment}
              buttonWidth={120} // todo: remove this line while removing en. suffix from language file
              onClick={cancelOrder}
            />
          </Stack>
          <Typography
            fontSize={12}
            fontWeight={600}
            color={COLOR_GRAY}
            marginTop={"12px"}
          >
            {displayTexts?.payment.orderId.replace(
              "{{orderId}}",
              data.current.request_id
            )}
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
}

export default RFIDPaymentMethodVariant1;
