import { useState, useEffect, useRef } from "react";
import QRCode from "qrcode.react";
import { useSelector } from "src/redux/store";
import { OrderService } from "src/services";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import {
  CircularProgress,
  Button,
  Box,
  Stack,
  Typography,
  Card,
  Paper,
} from "@mui/material";
import PressableButton from "src/components/pressables/PressableButton";
import { getCurrentLanguage } from "src/i18n";
import {
  COLOR_GRAY,
  COLOR_TEXT_ON_WHITE,
} from "src/theme/new-ui/component.color";
import generateAlpha from "src/theme/new-ui/color.utils";
import BorderedPushButton from "../../../../../components/pressables/BorderedPushButton";
import { fCurrency } from "src/utils/formatNumber";
import Countdown from "src/components/pressables/Countdown";

interface Props {
  /* timeLeft: number;
  setTimeLeft: (timeLeft: number) => void; */
  cancelOrder: VoidFunction;
  proceedToNextStep: VoidFunction;
  openErrorDialog: VoidFunction;
}

let checkStatusIntervalId: ReturnType<typeof setInterval>;

function PaytmPaymentMethodVariant1({
  /*  timeLeft,
  setTimeLeft, */
  cancelOrder,
  proceedToNextStep,
  openErrorDialog,
}: Props) {
  const { checkout } = useSelector((state) => state.product);
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const [isTryAgain, setIsTryAgain] = useState(false);
  const [qrData, setQrData] = useState("");
  const [isQrFeatched, setIsQrFeatched] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const data = useRef({
    request_id: checkout.orderId,
    payment_method: PAYMENT_METHODS.PAYTM,
  });

  const callCheckoutApi = async () => {
    try {
      const response = await OrderService.checkoutRequestV2(data.current);
      if (!response.error) {
        setIsTryAgain(false);
        setQrData(response.data);
        setIsQrFeatched(true);
        setIsPolling(true);
      } else {
        setIsTryAgain(true);
        return;
      }
    } catch (error) {
      // TODO: Handle Error
      console.error(error);
      openErrorDialog();
    }
  };

  const startCheckStatus = async () => {
    try {
      const response = await OrderService.checkStatus(data.current);
      if (response.error || response.status === "cancelled") openErrorDialog();
      if (!response.error && response.status === "success") proceedToNextStep();
    } catch (error) {
      console.error(error);
      // TODO: Handle Error

      openErrorDialog();
    }
  };

  useEffect(() => {
    callCheckoutApi();
    return () => {
      clearInterval(checkStatusIntervalId);
    };
  }, []);

  useEffect(() => {
    if (isPolling) {
      startCheckStatus();
      if (checkStatusIntervalId) clearInterval(checkStatusIntervalId);
      checkStatusIntervalId = setInterval(startCheckStatus, 7000);
    } else {
      clearInterval(checkStatusIntervalId);
    }
    return () => {
      clearInterval(checkStatusIntervalId);
    }
  }, [isPolling]);


  if (isTryAgain)
    return (
      <Button
        variant="outlined"
        sx={{ textTransform: "uppercase" }}
        onClick={() => {
          setIsTryAgain(false);
          callCheckoutApi();
        }}
      >
        Try Again
      </Button>
    );

  if (!isQrFeatched) return <CircularProgress color="error" />;

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 100,
        }}
        direction={"column"}
        alignItems={"center"}
        spacing={0}
        marginBottom={3}
      >
        <Typography fontSize={20} fontWeight={700} color={COLOR_TEXT_ON_WHITE}>
          {displayTexts?.payment.paytm.scanQrCode}
        </Typography>
        <Typography fontSize={12} fontWeight={600} color={COLOR_TEXT_ON_WHITE}>
          {displayTexts?.payment.paytm.scanAndPay}
        </Typography>
        <Countdown
          /* seconds={300}
          timeLeft={timeLeft}
          setTimeLeft={setTimeLeft} */
          onTimeout={cancelOrder}
        />
        <Typography fontSize={28} fontWeight={700} color={COLOR_TEXT_ON_WHITE}>
          {`₹ ${fCurrency(checkout.total)}`}
        </Typography>

        <BorderedPushButton>
          <QRCode value={qrData.toString()} includeMargin={false} />
        </BorderedPushButton>
      </Stack>

      <Stack
        sx={{
          position: "absolute",
          bottom: 20,
        }}
        direction={"column"}
        alignItems={"center"}
        spacing={0}
      >
        <PressableButton
          text={displayTexts?.payment.cancelPayment}
          buttonWidth={150} // todo: remove this line while removing en. suffix from language file
          onClick={cancelOrder}
        />
        <Typography
          fontSize={12}
          fontWeight={600}
          color={COLOR_GRAY}
          marginTop={"12px"}
        >
          {displayTexts?.payment.orderId.replace(
            "{{orderId}}",
            data.current.request_id
          )}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default PaytmPaymentMethodVariant1;
