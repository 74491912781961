import sum from "lodash/sum";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { IProductState, ICheckoutCartItem } from "../../@types/product";
//
import { dispatch } from "../store";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";

// ----------------------------------------------------------------------

const initialState: IProductState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    /* discount: 0,
    shipping: 0,
    billing: null, */
    payment: {
      value: "",
      phoneNo: "",
    },
    orderId: "",
    totalItems: 0,
  },
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart: ICheckoutCartItem[] = action.payload;

      const totalItems = sum(cart.map((product) => product.quantity));
      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      state.checkout.cart = cart;
      /*       state.checkout.discount = state.checkout.discount || 0;
      state.checkout.shipping = state.checkout.shipping || 0;
      state.checkout.billing = state.checkout.billing || null;
      */
      state.checkout.total = subtotal - /* state.checkout.discount */ 0;
      state.checkout.subtotal = subtotal;
      state.checkout.totalItems = totalItems;
    },

    addToCart(state, action) {
      const newProduct = action.payload;
      const isEmptyCart = !state.checkout.cart.length;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, newProduct];
      } else {
        state.checkout.cart = state.checkout.cart.map((product) => {
          const isExisted = product.id === newProduct.id;

          if (isExisted && !(product.quantity >= product.total_units)) {
            return {
              ...product,
              quantity: product.quantity + 1,
            };
          }

          return product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], "id");
      state.checkout.totalItems = sum(
        state.checkout.cart.map((product) => product.quantity)
      );
      state.checkout.total = sum(
        state.checkout.cart.map((product) => product.price * product.quantity)
      );
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter(
        (product) => product.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      /* state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      /*       state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null; *
      state.checkout.payment = null;
      state.checkout.totalItems = 0; */
      state.checkout = { ...initialState.checkout };
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;

      const updateCart = state.checkout.cart.map((product) => {
        if (
          product.id === productId &&
          !(product.quantity >= product.total_units)
        ) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
      state.checkout.totalItems = sum(
        state.checkout.cart.map((product) => product.quantity)
      );
      state.checkout.total = sum(
        state.checkout.cart.map((product) => product.price * product.quantity)
      );
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      // const updateCart = state.checkout.cart.map((product) => {
      //   if (product.id === productId) {
      //     return {
      //       ...product,
      //       quantity: product.quantity - 1,
      //     };
      //   }
      //   return product;
      // });

      let pIndex = state.checkout.cart.findIndex(
        (item) => item.id === productId
      );
      console.log("decreaseQuantity: ", state.checkout.cart);
      if (pIndex != -1) {
        if (state.checkout.cart[pIndex].quantity == 1)
          state.checkout.cart.splice(pIndex, 1);
        else state.checkout.cart[pIndex].quantity -= 1;
      }
      console.log("decreaseQuantity: ", state.checkout.cart);

      // state.checkout.cart = updateCart;
      state.checkout.totalItems = sum(
        state.checkout.cart.map((product) => product.quantity)
      );
      state.checkout.total = sum(
        state.checkout.cart.map((product) => product.price * product.quantity)
      );
    },

    removeProductFromCart(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.filter(
        (product) => product.id !== productId
      );

      state.checkout.cart = updateCart;
      state.checkout.totalItems = sum(
        state.checkout.cart.map((product) => product.quantity)
      );
      state.checkout.total = sum(
        state.checkout.cart.map((product) => product.price * product.quantity)
      );
    },

    createBilling(state, action) {
      console.log({ billing: action.payload });

      //state.checkout.billing = action.payload;
    },
    initOrderCycle(state, action) {
      state.checkout.orderId = action.payload.request_id;
    },
    createPaymentMethod(state, action) {
      state.checkout.payment = {
        ...state.checkout.payment,
        ...action.payload.payment,
      };
      //state.checkout.billing = action.payload;
    },

    // ! Not REQUIRED
    applyDiscount(state, action) {
      const discount = action.payload;
      //state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    // ! Not REQUIRED
    applyShipping(state, action) {
      /* const shipping = action.payload;
      //state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping; */
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  deleteCart,
  createBilling,
  initOrderCycle,
  createPaymentMethod,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  removeProductFromCart,
} = slice.actions;

// ----------------------------------------------------------------------

export function setProducts(products: any) {
  const MAX_ROWS = 7;
  const _superTempProducts = JSON.parse(JSON.stringify(products));
  console.log("PRODUCTS :: ", products);
  const _tempProducts: any = [];
  try {
    /* _superTempProducts.sort((a: any, b: any) => {
      const a_show_pos = parseInt(a.show_pos);
      const b_show_pos = parseInt(b.show_pos);
      if (a_show_pos < b_show_pos) return -1;
      if (a_show_pos > b_show_pos) return 1;
      return 0;
    }); */
    _superTempProducts.sort((a: any, b: any) => {
      const a_pos = parseInt(a.pos);
      const b_pos = parseInt(b.pos);
      if (a_pos < b_pos) return -1;
      if (a_pos > b_pos) return 1;
      return 0;
    });
    let count = 1;
    while (_superTempProducts.length > 0 && count <= MAX_ROWS) {
      //console.log("_superTempProducts.length", _superTempProducts.length);
      const _tempArray: any = [];
      let _tempCount = count * 100;
      for (let index = 0; index < _superTempProducts.length; index++)
        if (parseInt(_superTempProducts[index].pos) < _tempCount) {
          _tempArray.push(_superTempProducts.splice(index, 1)[0]);
          index -= 1;
        }

      count += 1;
      if (_tempArray.length > 0) _tempProducts.push(_tempArray);
    }
  } catch (error) {
    console.log(error);
  }

  // console.log(_tempProducts);

  return async () => {
    //dispatch(slice.actions.getProductsSuccess(products));
    dispatch(slice.actions.getProductsSuccess(_tempProducts));
  };
}

export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/products");
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/products/product", {
        params: { name },
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
