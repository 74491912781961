import React from "react";
import "./loader.css";
function Loader() {
  return (
    <div className="box_paymentScreenLoader">
      <div className="container_paymentScreenLoader">
        <span className="circle_paymentScreenLoader"></span>
        <span className="circle_paymentScreenLoader"></span>
        <span className="circle_paymentScreenLoader"></span>
        <span className="circle_paymentScreenLoader"></span>
      </div>
    </div>
  );
}

export default Loader;
