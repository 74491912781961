import { useRef, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// components
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import KeyboardWrapper from "src/components/keyBpardWrapper/KeyboardWrapper";
import { KeyboardInput } from "react-simple-keyboard";
import Lang from "src/i18n/en";

// ----------------------------------------------------------------------

type FormValuesProps = {
  machineId: string;
  password: string;
  afterSubmit?: string;
};

export default function AuthLoginForm() {
  const { login } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "demo@minimals.cc",
    password: "demo1234",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    clearErrors,
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(inputs.machineId, inputs.password);
    } catch (error) {
      console.error(error);

      reset();

      setError("afterSubmit", {
        ...error,
        message: error.message,
      });

      setTimeout(() => {
        clearErrors();
      }, 3000);
    }
  };

  const [inputs, setInputs] = useState<{ machineId: string; password: string }>(
    { machineId: "", password: "" }
  );
  // const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState("default");
  const keyboard = useRef({
    setInput: (inputVal: string) => {},
    setCaretPosition: (inputVal: number) => {},
  });

  const onChangeAll = (inputs: KeyboardInput) => {
    /* console.log("Inputs changed", inputs); */
    setInputs({ machineId: inputs.machineId, password: inputs.password });
  };

  const onChangeInput = (event: any) => {
    const inputVal = event.target.value;

    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };
  const getInputValue = (inputName: "machineId" | "password") => {
    return inputs[inputName] || "";
  };

  /*   const machineIdTextfieldRef = useRef<HTMLDivElement>(null);
  const passwordTextfieldRef = useRef<HTMLDivElement>(null); */

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ my: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          /* ref={machineIdTextfieldRef} */
          name="machineId"
          label={Lang.login.machineId}
          /* autoFocus */
          InputProps={{
            readOnly: true,
          }}
          value={getInputValue("machineId")}
          onChange={onChangeInput}
          onFocus={() => {
            /* machineIdTextfieldRef.current?.setSelectionRange(
              machineIdTextfieldRef.current.value.length,
              machineIdTextfieldRef.current.value.length
            ); */
            setInputName("machineId");
          }}
          focused={inputName === "machineId"}
        />

        <RHFTextField
          /* ref={passwordTextfieldRef} */
          name="password"
          label={Lang.login.password}
          type={showPassword ? "text" : "password"}
          value={getInputValue("password")}
          onChange={onChangeInput}
          onFocus={() => {
            setInputName("password");
          }}
          focused={inputName === "password"}
          InputProps={{
            readOnly: true,
            /* endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ), */
          }}
        />
      </Stack>

      {/* <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link variant="body2" color="inherit" underline="always">
          Forgot password?
        </Link>
      </Stack> */}

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          {Lang.login.login}
        </LoadingButton>
      </Stack>

      <KeyboardWrapper
        inputName={inputName}
        keyboardRef={keyboard}
        onChangeAll={onChangeAll}
      />
    </FormProvider>
  );
}
