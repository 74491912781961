import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Container, Stack, Typography } from "@mui/material";
// routes
// redux
import { useDispatch, useSelector } from "../../../redux/store";
// @types
// components

import { useSettingsContext } from "../../../components/settings";
// sections
import { OrderService } from "src/services";
import { PATH_AFTER_LOGIN } from "src/config";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant2/PageHeaderCrumb";
import { getCurrentLanguage } from "src/i18n";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant2/PageFooterCrumb";
import { COLOR_GRAY } from "src/theme/new-ui/component.color";
import FeedbackVendingMaching from "src/assets/feedback/feedback_screen.gif";
import PressableFeedback from "src/components/pressables/PressableFeedback";
import DialogFeedbackQR from "src/sections/dialogs/FeedbackQRDialog";
import { resetVendingState } from "src/redux/slices/vending";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { TIMEOUT_DEFAULT_VALUES } from "src/constants/index";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
let feedbackTimeoutID: ReturnType<typeof setTimeout>;

export default function EcommerceFeedbackPageVariant2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { machineConfiguration } = useMachineConfigurationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { checkout } = useSelector((state) => state.product);
  console.log({ checkout });
  //const { orderId } = checkout;

  const { themeStretch } = useSettingsContext();

  const { vendingStatus, orderId } = useSelector((state) => state.vending);
  const [feedbackType, setFeedbackType] = useState(-1);
  const [showFeedbackQr, setShowFeedbackQr] = useState(true);

  const handelFeedbackButtonsClick = async (value: number, bill_id: string) => {
    setFeedbackType(value);
    const response = await OrderService.orderFeedback({
      bill_id,
      value,
    });
    if (!response.error) postFeedback();
  };

  const clearSubscriptions = () => {
    console.log("hello EcommerceFeedbackPageVariant2");
    clearTimeout(feedbackTimeoutID);
  };

  const postFeedback = () => {
    setShowFeedbackQr(true);
  };

  const postFeedbackImpl = () => {
    setShowFeedbackQr(false);
    clearSubscriptions();
    navigate(PATH_AFTER_LOGIN);
  };

  useEffect(() => {
    dispatch(resetVendingState());
    feedbackTimeoutID = setTimeout(() => {
      navigate(PATH_AFTER_LOGIN);
    }, parseInt(machineConfiguration.kiosk_feedback_timeout ? machineConfiguration.kiosk_feedback_timeout : TIMEOUT_DEFAULT_VALUES.kiosk_feedback_timeout) * 1000);
    return clearSubscriptions;
  }, []);

  return (
    <>
      <Helmet>
        <title>{displayTexts?.vend.helmetTitle}</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
        }}
      >
        <PageHeaderCrumb
          elevation={0}
          handleHelpPageClick={() => {
            console.log("handleHelpPageClick");
          }}
        />

        <Stack
          sx={{
            display: "flex",
            height: "100%",
            marginTop: "80px",
            alignItems: "center",
          }}
          spacing={0}
        >
          <Typography
            marginTop={6}
            variant={"h6"}
            fontSize={36}
            fontWeight={700}
            lineHeight={"56px"}
            align="center"
            color={"#454F5B"}
            sx={{
              height: "72px",
              width: "746px",
              left: "108px",
              top: "198px",
              "border-radius": "nullpx",
            }}
          >
            {displayTexts?.vend.collectProduct}
          </Typography>

          <img
            src={FeedbackVendingMaching}
            alt={"test"}
            style={{
              width: "582px",
              marginTop: 40,
            }}
          />

          <Typography
            marginTop={10}
            fontSize={32}
            fontWeight={400}
            lineHeight={"48px"}
            align="center"
            color={"#454F5B"}
            textOverflow="false"
            sx={{
              height: "72px",
              left: "1px",
              top: "876px",
              "border-radius": "nullpx",
            }}
          >
            {displayTexts?.vend.rateExperience}
          </Typography>

          <Stack
            marginTop={2}
            display={"flex"}
            direction={"row"}
            width={"100%"}
            paddingLeft={10}
            paddingRight={10}
            justifyContent={"space-around"}
          >
            <PressableFeedback
              emoji="/assets/configAssets/img_horrible.svg"
              radius={192}
              shadowHeight={14}
              onClick={() => {
                handelFeedbackButtonsClick(0, orderId);
              }}
            />
            <PressableFeedback
              emoji="/assets/configAssets/img_ok.svg"
              radius={192}
              shadowHeight={14}
              onClick={() => {
                handelFeedbackButtonsClick(3, orderId);
              }}
            />
            <PressableFeedback
              emoji="/assets/configAssets/img_amazing.svg"
              radius={192}
              shadowHeight={14}
              onClick={() => {
                handelFeedbackButtonsClick(5, orderId);
              }}
            />
          </Stack>
          <Stack
            marginTop={4}
            display={"flex"}
            direction={"row"}
            width={"100%"}
            paddingLeft={10}
            paddingRight={10}
            justifyContent={"space-around"}
          >
            <Typography
              fontSize={28}
              fontWeight={700}
              lineHeight={"48px"}
              align="center"
              color={"#454F5B"}
            >
              {displayTexts?.vend.horrible}
            </Typography>
            <Typography
              fontSize={28}
              fontWeight={700}
              lineHeight={"48px"}
              align="center"
              color={"#454F5B"}
            >
              {displayTexts?.vend.justok}
            </Typography>
            <Typography
              fontSize={28}
              fontWeight={700}
              lineHeight={"48px"}
              align="center"
              color={"#454F5B"}
            >
              {displayTexts?.vend.amazing}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            marginBottom: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            position={"fixed"}
            sx={{ bottom: "60px" }}
            fontSize={28}
            fontWeight={600}
            color={COLOR_GRAY}
          >
            {displayTexts?.payment.orderId.replace("{{orderId}}", orderId)}
          </Typography>
          <PageFooterCrumb elevation={10} />
        </Box>

        {showFeedbackQr && (
          <DialogFeedbackQR
            marginScale={2}
            dialogRadius={48}
            dialogShadow={20}
            titleSize={48}
            subtitleSize={28}
            actionSize={24}
            actionHeight={64}
            actionShadow={12}
            actionRadius={32}
            qrWidth={300}
            open={showFeedbackQr}
            isPositive={feedbackType != 0}
            onContinue={postFeedbackImpl}
          />
        )}
      </Container>
    </>
  );
}
