import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "../../../redux/store";
import VendingVariant from "src/variants/VendingVariant";
import { resetCart } from "../../../redux/slices/product";
import { nextVendingItem, setVendingStatus, resetVendingState } from "src/redux/slices/vending";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { SocketEvents } from "src/observers/socketObserver";
import SocketObserver from "src/observers/socketObserver";
import React from "react";
import { SocketContext } from "src/context/socketContext/SocketContext";
import { useSelector } from "../../../redux/store";
import { VendingService } from "src/services";

// ----------------------------------------------------------------------

const vendedItems: Array<string> = [];
let waitingIntervalId: ReturnType<typeof setTimeout>;

export default function VendingPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { refetchMachineItemsFetched, machineData, machineConfig } = useMachineConfigurationContext();

    const { vendingItems, orderId, vendingStatus, currentItem } = useSelector(
        (state) => state.vending
    );

    const socketObserverInstance = SocketObserver.getInstance();

    const handleOrderItemResponse = (data: any) => {
        const resp = JSON.parse(data);
        vendedItems.push(data);
        dispatch(nextVendingItem(resp.s));
    };

    const handleNoItemResponse = async () => {
        try {
            const currentItemVending = vendingItems.filter((item) => {
                return item.bill_item_id === currentItem.bill_item_id;
            });
            const vendItemBody = {
                mid: machineData.machine_id,
                oid: orderId,
                oiid: currentItemVending[0].bill_item_id,
                oir: "VEND_FAILED_NO_ITEM_RESPONSE_FROM_VMC",
                items: currentItemVending[0].position,
                s: false,
            };
            vendedItems.push(JSON.stringify(vendItemBody));
            clearTimeout(waitingIntervalId);
            dispatch(nextVendingItem(vendItemBody.s));
        } catch (e) {
            console.log(e);
        }
    }

    const handleOrderWithoutVending = async (): Promise<any> => {
        try {
            const itemsVended = vendedItems.map((item) => {
                return JSON.parse(item).oiid;
            });

            const itemsNotVended = vendingItems.filter((item) => {
                return !itemsVended.includes(item.bill_item_id);
            });

            if (itemsNotVended.length > 0) {
                itemsNotVended.map((item) => {
                    const vendItemBody = {
                        mid: machineData.machine_id,
                        oid: orderId,
                        oiid: item.bill_item_id,
                        oir: "VEND_FAILED_VMC_DISCONNECTED",
                        items: item.position,
                        s: false,
                    };
                    vendedItems.push(JSON.stringify(vendItemBody));
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const sendItemResponses = async () => {
        try {
            await VendingService.setVendedItemResponses(orderId, vendedItems);
        } catch (err) {
            console.log(err)
        }
    }

    const handleOrderStatus = (data: any) => {
        setTimeout(() => {
            clearAllSubscriptions();
            dispatch(setVendingStatus());
            dispatch(resetCart());
            console.log(vendedItems);
            sendItemResponses();
            navigate(PATH_DASHBOARD.eCommerce.feedback);
        }, 5000);
    };

    const clearAllSubscriptions = () => {
        socketObserverInstance.unsubscribeMultiple(subscriptionIdList.current);
    };
    const subscriptionIdList = useRef<Array<string>>([]);

    useEffect(() => {
        if (vendingItems.length > 0 && Object.keys(currentItem).length > 0 && vendedItems.length <= vendingItems.length) {
            waitingIntervalId = setTimeout(() => {
                handleNoItemResponse();
            }, machineConfig?.vendingPage?.vendItemMaxTime || 60000);
        }

        return () => {
            clearTimeout(waitingIntervalId);
        }
    }, [currentItem]);

    useEffect(() => {
        subscriptionIdList.current.push(
            socketObserverInstance.subscribe(
                SocketEvents.ORDER_ITEM_RESPONSE,
                handleOrderItemResponse
            )
        );
        subscriptionIdList.current.push(
            socketObserverInstance.subscribe(
                SocketEvents.ORDER_STATUS,
                handleOrderStatus
            )
        );

        return () => {
            refetchMachineItemsFetched();
            clearAllSubscriptions();
            // reset vending state and cart aswell
            // vendedItems.length = 0;
            if (vendingStatus === "IN_PROGRESS") {
                handleOrderWithoutVending();
                // sendItemResponses();
                vendedItems.length = 0;
                dispatch(resetVendingState());
                dispatch(resetCart());
            }
        };
    }, []);

    return (
        <>
            <VendingVariant />
        </>
    );
}
