import { Helmet } from "react-helmet-async";
// sections
import Login from "../../sections/auth/Login";
import Lang from "src/i18n/en";

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>{Lang.login.helmetTitle}</title>
      </Helmet>
      <Login />
    </>
  );
}
