import { useRef } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
// utils
// components
import Carousel from "../../../../components/carousel";

// ----------------------------------------------------------------------

type Props = {
  data: any;
  index: number;
  spacing?: number;
  ShopProductCardComponent: React.ElementType;
};

export default function CarouselCenterMode({
  data,
  index,
  spacing = 1,
  ShopProductCardComponent,
}: Props) {
  const carouselRef = useRef<Carousel | null>(null);
  const theme = useTheme();

  const carouselSettings = {
    slidesToShow: Math.min(data.length, 3),
    infinite: false,
    rtl: Boolean(theme.direction === "rtl"),
    speed: 500,
    edgeFriction: 0,
    variableWidth: true,
    loop: false,
    swipeToSlide: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: { slidesToShow: Math.min(data.length, 5) },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: { slidesToShow: Math.min(data.length, 5) },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: Math.min(data.length, 5),
    //       centerPadding: "0",
    //     },
    //   },
    // ],
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        position: "relative",
      }}
      key={`CAROUSEL_${index}`}
    >
      <Carousel ref={carouselRef} {...carouselSettings}>
        {data.map((product: any, index: number) => {
          return (
            <Box key={product.id} sx={{ px: spacing }}>
              <ShopProductCardComponent key={product.id} product={product} />
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
}
