import { CircularProgress, Grid } from '@mui/material';
import { COLOR_PRIMARY } from 'src/theme/new-ui/component.color';

export default function OverlayLoading() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <CircularProgress sx={
        {
          color: COLOR_PRIMARY
        }} />
    </Grid>
  );
};

