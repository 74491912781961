import axios, { axiosInstanceV2 } from "../utils/axios";
import { ItemResponse } from "src/@types/vending";

// * 1.4 V2
async function vendNow(order_id: string): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/vendNow",
      data: { order_id },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function setVendedItemResponses(orderId: string, itemResponses: string[]): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/setVendedItemResponses",
      data: { orderId, itemResponses },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error)
  }
}

export { vendNow, setVendedItemResponses };
