import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { KeyboardInput } from "react-simple-keyboard";
import { PaymentLoader } from "src/components/payment-loader";
import Countdown from "src/components/pressables/Countdown";
import PressableButton from "src/components/pressables/PressableButton";
import PressableDialPad from "src/components/pressables/dialpad/PressableDialPad";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import { getCurrentLanguage } from "src/i18n";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { useNotificationContext } from "src/context/notification/useNotificationContext";
import { createPaymentMethod } from "src/redux/slices/product";
import { dispatch, useSelector } from "src/redux/store";
import { OrderService } from "src/services";
import {
  ButtonGrayTextColor,
  COLOR_GRAY,
  COLOR_TEXT_ON_WHITE,
} from "src/theme/new-ui/component.color";
import { fCurrency } from "src/utils/formatNumber";

const TAG = "/new-ui/SwiftPaymentMethod";

interface Props {
  cancelOrder: VoidFunction;
  proceedToNextStep: VoidFunction;
  inputs: {
    phoneNo: string;
    otp?: string;
  };
  openErrorDialog: VoidFunction;
  setInputs: Dispatch<
    SetStateAction<{
      phoneNo: string;
      otp?: string;
    }>
  >;
}

function SwiftPaymentMethodVariant2({
  proceedToNextStep,
  cancelOrder,
  inputs,
  setInputs,
}: Props) {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );
  const { currencyDetails } = useMachineConfigurationContext();
  const notification = useNotificationContext();
  const { checkout } = useSelector((state) => state.product);

  const data = useRef({
    request_id: checkout.orderId,
    payment_method: PAYMENT_METHODS.SWIFT,
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isPhoneNoAdded, setIsPhoneNoAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [dialed, setDialed] = useState("");
  const [code, setCode] = useState("+91");

  useEffect(() => {
    setInputs({ phoneNo: phoneNumber, otp: otp });
  }, [phoneNumber, otp]);

  const callCheckoutApi = async () => {
    try {
      const response = await OrderService.checkoutRequestV2({
        ...data.current,
        phone: phoneNumber,
      });

      if (response.error) {
        console.log(response.error);
        // setIsError(true);
        notification.notify(response.error, null, {
          height: 80,
          radius: 32,
          marginTop: 20,
          maxWidth: 800,
          shadowHeight: 12,
          padding: 2,
          fontSize: 32,
        });
        return;
      }
      setIsPhoneNoAdded(true);
      // setIsLoading(false);
      setDialed("");
    } catch (error) {
      console.error(`${TAG}.callCheckoutApi.catch: `, error);
      if (error.message) {
        notification.notify(error.message, null, {
          height: 80,
          radius: 32,
          marginTop: 20,
          maxWidth: 800,
          shadowHeight: 12,
          padding: 2,
          fontSize: 32,
        });
      }
      // setIsError(true);
      setIsPhoneNoAdded(false);
      // setIsLoading(false);
    }
  };

  const validateSwiftOtp = async () => {
    const data: {
      request_id: string;
      otp: string;
      phone_number: string;
    } = {
      request_id: checkout.orderId,
      otp: otp,
      phone_number: phoneNumber,
    };
    try {
      const response = await OrderService.authoriseSwiftPayment(data);
      if (response.error) cancelOrder();
      proceedToNextStep();
    } catch (error) {
      console.error(`${TAG}.validateSwiftOtp.catch: `, error);
    }
  };

  const resetEverything = () => {
    setIsPhoneNoAdded(false);
    setPhoneNumber("");
    setOtp("");
    setDialed("");
    // setCode(""); // do not reset phone code for now
    // setIsLoading(false);
    // setIsError(false);
  };

  return (
    <div>
      {!isError && isLoading && <CircularProgress color="error" />}
      {!isError && !isLoading && (
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            sx={{
              position: "absolute",
              top: 200,
            }}
            direction={"column"}
            alignItems={"center"}
            spacing={0}
            marginBottom={6}
          >
            <Typography
              fontSize={40}
              fontWeight={700}
              color={COLOR_TEXT_ON_WHITE}
            >
              {isPhoneNoAdded
                ? displayTexts?.payment.swift.enterOtp
                : displayTexts?.payment.swift.enterPhone}
            </Typography>
            <Countdown onTimeout={cancelOrder} fontSize={24} />
            <Typography
              fontSize={56}
              fontWeight={700}
              color={COLOR_TEXT_ON_WHITE}
            >
              {`${
                currencyDetails && currencyDetails.isSuffix
                  ? ""
                  : currencyDetails?.symbol + " "
              }${fCurrency(checkout.total)}${
                currencyDetails && currencyDetails.isSuffix
                  ? " " + currencyDetails?.symbol
                  : ""
              }`}
            </Typography>
            <PressableDialPad
              actionButtonHeight={64}
              actionButtonRadius={16}
              actionButtonShadow={8}
              actionTextSize={24}
              digitDimen={100}
              digitFontSize={32}
              digitShadowHeight={16}
              digitMarginTop={4}
              digitSpacing={8}
              inputMarginBottom={4}
              inputSpacing={2}
              inputBorderWidth={2}
              inputPadHor={32}
              inputPadVert={20}
              inputFieldHeight={92}
              inputFieldRadius={16}
              inputFieldShadow={12}
              inputTextSize={32}
              dialed={dialed}
              setDialed={setDialed}
              code={code}
              setCode={setCode}
              isPhoneField={!isPhoneNoAdded}
              actionText={`${
                isPhoneNoAdded
                  ? displayTexts?.payment.swift.verify
                  : displayTexts?.payment.swift.sendOtp
              }`}
              onDialed={(data0, data1) => {
                console.log("callCheckoutApi: ", data0);
                if (isPhoneNoAdded) setOtp(data0);
                else
                  setPhoneNumber(
                    // data1 ? data1 + data0 : data0
                    data0
                  );
              }}
              onAction={async (data) => {
                if (!isPhoneNoAdded) {
                  // setIsLoading(true);
                  dispatch(
                    createPaymentMethod({
                      payment: { phoneNo: "91" + phoneNumber },
                    })
                  );
                  callCheckoutApi();
                } else {
                  await validateSwiftOtp();
                }
              }}
            />
          </Stack>

          <Stack
            sx={{
              position: "absolute",
              bottom: 0,
            }}
            direction={"column"}
            alignItems={"center"}
            spacing={0}
          >
            <Stack direction={"row"} spacing={2}>
              <PressableButton
                text={displayTexts?.payment.swift.changeNumber}
                buttonWidth={300} // todo: remove this line while removing en. suffix from language file
                buttonHeight={60}
                borderRadius={30}
                fontSize={24} // todo: remove this line while removing en. suffix from language file
                onClick={resetEverything}
                colorType={ButtonGrayTextColor}
              />
              <PressableButton
                text={displayTexts?.payment.cancelPayment}
                buttonWidth={300} // todo: remove this line while removing en. suffix from language file
                buttonHeight={60}
                borderRadius={30}
                fontSize={24} // todo: remove this line while removing en. suffix from language file
                onClick={cancelOrder}
              />
            </Stack>
            <Typography
              fontSize={24}
              fontWeight={600}
              color={COLOR_GRAY}
              marginTop={"12px"}
            >
              {displayTexts?.payment.orderId.replace(
                "{{orderId}}",
                data.current.request_id
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
      {/* {isError && (
        <Typography variant="body2" gutterBottom align="center" color={"error"}>
          {displayTexts?.payment.swift.accountExists}
        </Typography>
      )} */}
    </div>
  );
}

export default SwiftPaymentMethodVariant2;
